import React, { useState, useEffect } from 'react';
import Flag from 'react-world-flags';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SearchIcon } from "../../../assets/svg/search.svg";
import { ReactComponent as NormalHeart } from "../../../assets/svg/normal.svg";
import { ReactComponent as HoverHeart } from "../../../assets/svg/hover.svg";
import { ReactComponent as ClickedHeart } from "../../../assets/svg/clicked.svg";
import { getWeekRange, parseJWT, getTypeTag } from '../../../utils';
import io from 'socket.io-client';
import axios from 'axios';

// Add interface for country options
interface CountryOption {
  value: string;
  label: JSX.Element;
  name: string;
}

// Add country data
const countries: CountryOption[] = [
  { value: 'AUT', label: <div className="flex items-center gap-2"><Flag code="AUT" className="w-4 h-4" />Austria</div>, name: 'Austria' },
  { value: 'BEL', label: <div className="flex items-center gap-2"><Flag code="BEL" className="w-4 h-4" />Belgium</div>, name: 'Belgium' },
  { value: 'BGR', label: <div className="flex items-center gap-2"><Flag code="BGR" className="w-4 h-4" />Bulgaria</div>, name: 'Bulgaria' },
  { value: 'HRV', label: <div className="flex items-center gap-2"><Flag code="HRV" className="w-4 h-4" />Croatia</div>, name: 'Croatia' },
  { value: 'CYP', label: <div className="flex items-center gap-2"><Flag code="CYP" className="w-4 h-4" />Cyprus</div>, name: 'Cyprus' },
  { value: 'CZE', label: <div className="flex items-center gap-2"><Flag code="CZE" className="w-4 h-4" />Czech Republic</div>, name: 'Czech Republic' },
  { value: 'DNK', label: <div className="flex items-center gap-2"><Flag code="DNK" className="w-4 h-4" />Denmark</div>, name: 'Denmark' },
  { value: 'EST', label: <div className="flex items-center gap-2"><Flag code="EST" className="w-4 h-4" />Estonia</div>, name: 'Estonia' },
  { value: 'FIN', label: <div className="flex items-center gap-2"><Flag code="FIN" className="w-4 h-4" />Finland</div>, name: 'Finland' },
  { value: 'FRA', label: <div className="flex items-center gap-2"><Flag code="FRA" className="w-4 h-4" />France</div>, name: 'France' },
  { value: 'DEU', label: <div className="flex items-center gap-2"><Flag code="DEU" className="w-4 h-4" />Germany</div>, name: 'Germany' },
  { value: 'GRC', label: <div className="flex items-center gap-2"><Flag code="GRC" className="w-4 h-4" />Greece</div>, name: 'Greece' },
  { value: 'HUN', label: <div className="flex items-center gap-2"><Flag code="HUN" className="w-4 h-4" />Hungary</div>, name: 'Hungary' },
  { value: 'ISL', label: <div className="flex items-center gap-2"><Flag code="ISL" className="w-4 h-4" />Iceland</div>, name: 'Iceland' },
  { value: 'IRL', label: <div className="flex items-center gap-2"><Flag code="IRL" className="w-4 h-4" />Ireland</div>, name: 'Ireland' },
  { value: 'ITA', label: <div className="flex items-center gap-2"><Flag code="ITA" className="w-4 h-4" />Italy</div>, name: 'Italy' },
  { value: 'LVA', label: <div className="flex items-center gap-2"><Flag code="LVA" className="w-4 h-4" />Latvia</div>, name: 'Latvia' },
  { value: 'LIE', label: <div className="flex items-center gap-2"><Flag code="LIE" className="w-4 h-4" />Liechtenstein</div>, name: 'Liechtenstein' },
  { value: 'LTU', label: <div className="flex items-center gap-2"><Flag code="LTU" className="w-4 h-4" />Lithuania</div>, name: 'Lithuania' },
  { value: 'LUX', label: <div className="flex items-center gap-2"><Flag code="LUX" className="w-4 h-4" />Luxembourg</div>, name: 'Luxembourg' },
  { value: 'MLT', label: <div className="flex items-center gap-2"><Flag code="MLT" className="w-4 h-4" />Malta</div>, name: 'Malta' },
  { value: 'NLD', label: <div className="flex items-center gap-2"><Flag code="NLD" className="w-4 h-4" />Netherlands</div>, name: 'Netherlands' },
  { value: 'NOR', label: <div className="flex items-center gap-2"><Flag code="NOR" className="w-4 h-4" />Norway</div>, name: 'Norway' },
  { value: 'POL', label: <div className="flex items-center gap-2"><Flag code="POL" className="w-4 h-4" />Poland</div>, name: 'Poland' },
  { value: 'PRT', label: <div className="flex items-center gap-2"><Flag code="PRT" className="w-4 h-4" />Portugal</div>, name: 'Portugal' },
  { value: 'ROU', label: <div className="flex items-center gap-2"><Flag code="ROU" className="w-4 h-4" />Romania</div>, name: 'Romania' },
  { value: 'SVK', label: <div className="flex items-center gap-2"><Flag code="SVK" className="w-4 h-4" />Slovakia</div>, name: 'Slovakia' },
  { value: 'SVN', label: <div className="flex items-center gap-2"><Flag code="SVN" className="w-4 h-4" />Slovenia</div>, name: 'Slovenia' },
  { value: 'ESP', label: <div className="flex items-center gap-2"><Flag code="ESP" className="w-4 h-4" />Spain</div>, name: 'Spain' },
  { value: 'SWE', label: <div className="flex items-center gap-2"><Flag code="SWE" className="w-4 h-4" />Sweden</div>, name: 'Sweden' },
  { value: 'CHE', label: <div className="flex items-center gap-2"><Flag code="CHE" className="w-4 h-4" />Switzerland</div>, name: 'Switzerland' },
  // Add more countries as needed
];

// Add this component back after the countries array
const CountryDisplay: React.FC<{ countryCode: string | null }> = ({ countryCode }) => {
    if (!countryCode) return null;

    const code = Array.isArray(countryCode) ? countryCode[0] : countryCode;
    const country = countries.find(c => c.value === code);
    
    return (
        <div className="group relative">
            <div className="w-6 h-6 overflow-hidden rounded-full flex items-center justify-center bg-gray-100 border border-gray-200">
                <Flag 
                    code={code}
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                    }}
                />
            </div>
            {/* Tooltip */}
            <div className="invisible group-hover:visible absolute z-20 bg-gray-900 text-white text-xs rounded px-2 py-1 -top-8 left-1/2 transform -translate-x-1/2 whitespace-nowrap">
                {country?.name || code}
            </div>
        </div>
    );
};

// Update Notice interface to include status
interface Notice {
  _id?: string;
  'title-proc': string | null;
  'organisation-name-buyer': Record<string, string[]> | null;
  'total-value': number | 'N/A';
  'publication-date': string;
  'deadline': string;
  'description-proc': string | null;
  'form-type': string;
  'publication-number': string;
  'organisation-country-buyer': string | null;
  'status'?: string;
  'isAddedToWorkSpace'?: boolean;
  'id': string;
}

interface NoticesData {
  notices: Notice[];
  totalNoticeCount: number;
}

// Add this helper function for country code
const getCountryCode = (country: string | string[] | null): string => {
  if (!country) return '';
  
  // Log the incoming country data to see what we're getting
  console.log('Country data:', country);
  
  const code = Array.isArray(country) ? country[0] : country;
  console.log('Extracted code:', code);
  
  // Convert 3-letter codes to 2-letter codes
   const codeMap: { [key: string]: string } = {
    'FRA': 'FR',
    'DEU': 'DE',
    'ITA': 'IT',
    'ESP': 'ES',
    'GBR': 'GB',
    'NOR': 'NO',
    'DNK': 'DK',
    'SWE': 'SE',
    'NLD': 'NL',
    'BEL': 'BE',
    'CHE': 'CH',
    'AUT': 'AT',
    'POL': 'PL',
    'PRT': 'PT',
    // Add more as needed
  };

  const mappedCode = codeMap[code] || '';
  console.log('Mapped code:', mappedCode);
  return mappedCode;
};

// Add SVG components for the icons
const MoneyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#000000"
    strokeLinecap="round"
    strokeLinejoin="round"
    width="16"
    height="16"
    strokeWidth="1"
  >
    <path d="M9 14c0 1.657 2.686 3 6 3s6 -1.343 6 -3s-2.686 -3 -6 -3s-6 1.343 -6 3z"></path>
    <path d="M9 14v4c0 1.656 2.686 3 6 3s6 -1.344 6 -3v-4"></path>
    <path d="M3 6c0 1.072 1.144 2.062 3 2.598s4.144 .536 6 0c1.856 -.536 3 -1.526 3 -2.598c0 -1.072 -1.144 -2.062 -3 -2.598s-4.144 -.536 -6 0c-1.856 .536 -3 1.526 -3 2.598z"></path>
    <path d="M3 6v10c0 .888 .772 1.45 2 2"></path>
    <path d="M3 11c0 .888 .772 1.45 2 2"></path>
  </svg>
);

const CalendarIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#000000"
    strokeLinecap="round"
    strokeLinejoin="round"
    width="16"
    height="16"
    strokeWidth="1"
  >
    <path d="M4 7a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12z"></path>
    <path d="M16 3v4"></path>
    <path d="M8 3v4"></path>
    <path d="M4 11h16"></path>
    <path d="M7 14h.013"></path>
    <path d="M10.01 14h.005"></path>
    <path d="M13.01 14h.005"></path>
    <path d="M16.015 14h.005"></path>
    <path d="M13.015 17h.005"></path>
    <path d="M7.01 17h.005"></path>
    <path d="M10.01 17h.005"></path>
  </svg>
);

const BellIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#000000"
    strokeLinecap="round"
    strokeLinejoin="round"
    width="16"
    height="16"
    strokeWidth="1"
  >
    <path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6"></path>
    <path d="M9 17v1a3 3 0 0 0 6 0v-1"></path>
    <path d="M21 6.727a11.05 11.05 0 0 0 -2.794 -3.727"></path>
    <path d="M3 6.727a11.05 11.05 0 0 1 2.792 -3.727"></path>
  </svg>
);

// Update the TenderDetails interface to match the API response
interface TenderDetails {
  title: string;
  description: string;
  buyer: {
    name: string;
    country: string;
    organization: string;
  };
  dates: {
    published: string;
    deadline: string;
    dispatched: string;
  };
  value: {
    total?: number;
  };
  criteria: {
    award?: string[];
    selection?: string[];
  };
  submission: {
    language: string[] | string;
  };
  documents: {
    competition?: string;
    internet_address?: string;
    ted: {
      html: string;
      htmlDirect: string;
    };
  };
  type: {
    form: string;
    notice: string;
    procedure: string;
    contract_nature?: string;
  };
  legal: {
    basis: string[] | string;
  };
  classification: {
    main: {
      code: string[];
      description: string;
    } | null;
    additional: string[] | null;
  };
  lots?: {
    titles?: {
      eng?: string[];
      // Add other language codes as needed
    };
    durations?: Array<{
      unit: string;
      value: string;
    }>;
    codes?: string[];
  };
}

// Add Modal component at the top level of the file
const Modal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}> = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  const handleBackdropClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4"
      onClick={handleBackdropClick}
    >
      <div className="bg-white rounded-lg w-full max-w-4xl max-h-[90vh] overflow-y-auto">
        <div className="p-6">
          <div className="flex justify-end">
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

// Add this new interface for the workspace notice
interface WorkspaceNotice {
    id: string;
    tenderText: string;
    shortDescription: string;
    customerName: string;
    tenderURL: string;
    tenderType: string;
    tenderDate: string;
    date: string;
    competitionDocsUrl: string;
    estimatedValueAmount: number | 'N/A';
    estimatedValueCurrencyCode: string;
    type: string;
    status: string;
    source: string;
    relevanceScore: string;
    color: string;
}

// Update the TedOpportunitiesProps interface
interface TedOpportunitiesProps {
    isChatWindowClosed?: boolean;
    clickedHeartIndex?: string[];
    hoverHeartIndex?: string;
    setHover?: (id: string | number | null) => void;
    addToWorkSpace: (opportunity: any, index: any, tab: any) => void;
    searchQuery?: string;
    currentDate: Date;
    dateType: string;
}

// Add this helper function at the top of the file
const generateObjectId = () => {
    const timestamp = Math.floor(new Date().getTime() / 1000).toString(16);
    const randomPart = Math.random().toString(16).substring(2, 14);
    return timestamp + randomPart.padEnd(24 - timestamp.length, '0');
};

// Add these before the component definition
const socketId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
const user = parseJWT(localStorage.getItem("tender_auth"));

// Then update the Socket setup
const Socket = io(`${process.env.REACT_APP_API_URL}`, {
    query: { socketId: socketId }
});

// Add this helper function to map TED form types to standard types
const mapFormTypeToType = (formType: string): string => {
    const typeMap: { [key: string]: string } = {
        'competition': 'ANNOUNCEMENT_OF_COMPETITION',
        'planning': 'ADVISORY_NOTICE',
        'result': 'ANNOUNCEMENT_OF_CONCLUSION_OF_CONTRACT',
        'contract': 'NOTICE_ON_BUYER_PROFILE',
        'cont-modif': 'CHANGE_OF_CONCLUSION_OF_CONTRACT',
        'dir-awa-pre': 'QUALIFICATION_SCHEME'
    };
    const mappedType = typeMap[formType?.toLowerCase()];
    console.log('Original form type:', formType);
    console.log('Mapped type:', mappedType);
    return mappedType || formType?.toUpperCase() || '';
};

// Update the component definition
const TedOpportunities: React.FC<TedOpportunitiesProps> = ({ 
    isChatWindowClosed = true,
    clickedHeartIndex = [],
    hoverHeartIndex,
    setHover = () => {},
    addToWorkSpace = () => {},
    searchQuery = '',
    currentDate,
    dateType
}) => {
    const { t, i18n } = useTranslation();
    const [data, setData] = useState<NoticesData | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [filters, setFilters] = useState({
        competition: true
    });
    const [selectedCountries, setSelectedCountries] = useState<CountryOption[]>([]);
    const [selectedNotice, setSelectedNotice] = useState<Notice | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [noticeDetails, setNoticeDetails] = useState<TenderDetails | null>(null);
    const [pageCount, setPageCount] = useState<number>(1);
    const ITEMS_PER_PAGE = 54;  // Changed from 50 to 54

    // Move the form type mapping inside the component to use t
    const formTypeMap: { [key: string]: string } = {
        'competition': t('Competition'),
        'planning': t('Planning'),
        'result': t('Result'),
        'contract': t('Contract'),
        'cont-modif': t('Contract Modification'),
        'dir-awa-pre': t('Direct Award Prequalification')
    };

    // Update the capitalizeFormType function to use the mapped translations
    const capitalizeFormType = (formType: string): string => {
        if (!formType) return '-';
        const key = formType.toLowerCase();
        return formTypeMap[key] || formType;
    };

    // Simplified formatDate function for debugging
    const formatDate = (dateString: string) => {
        if (!dateString) return "No date";
        return dateString; // Temporarily just return the raw date string to see what we're getting
    };

    // Simplified getRemainingDays function for debugging
    const getRemainingDays = (deadline: any) => {
        if (!deadline) return "No deadline";
        return deadline; // Temporarily just return the raw deadline to see what we're getting
    };

    const formatShortDate = (dateString: string) => {
        if (!dateString) return "-";
        try {
            // Remove the timezone offset if it exists
            const cleanDateString = dateString.split('+')[0];
            const date = new Date(cleanDateString);
            if (isNaN(date.getTime())) return "-";
            
            return date.toLocaleDateString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit'
            });
        } catch {
            return "-";
        }
    };

    const getFirstDeadline = (deadline: string | string[]) => {
        if (Array.isArray(deadline)) {
            return deadline[0];
        }
        return deadline;
    };

    // Remove any trailing slash from the API_URL when concatenating
    const baseUrl = process.env.REACT_APP_API_URL?.replace(/\/$/, '');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem("tender_auth");
                const weekRange = getWeekRange(currentDate);
                
                // Create an array of all dates in the week
                const dates: string[] = [];
                let currentDay = new Date(weekRange.start.year, weekRange.start.month - 1, weekRange.start.day);
                const endDay = new Date(weekRange.end.year, weekRange.end.month - 1, weekRange.end.day);
                
                while (currentDay <= endDay) {
                    dates.push(currentDay.toISOString().slice(0,10).replace(/-/g,''));
                    currentDay.setDate(currentDay.getDate() + 1);
                }

                // Fetch notices for each date and combine them
                const allNotices: Notice[] = [];
                for (const date of dates) {
                    const response = await fetch(`${baseUrl}/api/notices`, {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ date })
                    });

                    if (!response.ok) {
                        const errorData = await response.json();
                        console.error('API Error:', {
                            status: response.status,
                            statusText: response.statusText,
                            error: errorData
                        });
                        continue;
                    }
                    const jsonData = await response.json();
                    if (jsonData.notices) {
                        allNotices.push(...jsonData.notices);
                    }
                }

                setData({
                    notices: allNotices,
                    totalNoticeCount: allNotices.length
                });
            } catch (err) {
                console.error('Fetch error:', err);
                setError(err instanceof Error ? err.message : 'An error occurred');
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [currentDate]);

    // Add this new function to fetch notice details
    const fetchNoticeDetails = async (publicationNumber: string) => {
        try {
            console.log('Fetching details for notice:', publicationNumber);
            const response = await fetch(`${baseUrl}/api/notice/${publicationNumber}`);
            
            if (!response.ok) {
                const errorData = await response.json();
                console.error('Failed to fetch notice details:', {
                    status: response.status,
                    statusText: response.statusText,
                    error: errorData
                });
                throw new Error(errorData.message || 'Failed to fetch notice details');
            }
            
            const details = await response.json();
            console.log('Received notice details:', details);
            setNoticeDetails(details);
        } catch (error) {
            console.error('Error fetching notice details:', error);
        }
    };

    // Update the handleTenderClick function
    const handleTenderClick = async (notice: Notice) => {
        setSelectedNotice(notice);
        setIsModalOpen(true);
        await fetchNoticeDetails(notice['publication-number']);
    };

    // Update filter to include country selection
    const filteredNotices = data?.notices?.filter(notice => {
        const matchesSearch = !searchQuery.trim() || 
            notice['title-proc']?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            notice['organisation-name-buyer']?.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
            notice['description-proc']?.toLowerCase().includes(searchQuery.toLowerCase());

        const matchesCompetition = !filters.competition || 
            notice['form-type']?.toLowerCase().includes('competition');
        
        const matchesCountry = selectedCountries.length === 0 || 
            selectedCountries.some(country => 
                notice['organisation-country-buyer'] === country.value ||
                (Array.isArray(notice['organisation-country-buyer']) && 
                    notice['organisation-country-buyer'].includes(country.value))
            );

        return matchesSearch && matchesCompetition && matchesCountry;
    }) ?? [];

    // Update the scrollToTop function to be more reliable
    const scrollToTop = () => {
        const element = document.querySelector('.safari-scroll');
        if (element) {
            element.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        } else {
            // Fallback if .safari-scroll is not found
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    if (isLoading) return <div className="flex justify-center items-center h-screen">Loading...</div>;
    if (error) return <div className="text-red-500">Error: {error}</div>;
    if (!data) return <div>No data available</div>;

    return (
        <div className={`safari-scroll scrollbar-hide h-[300px] md:h-[calc(100vh-115px)]`}>
            {/* Filters Section */}
            <div className="flex flex-wrap m-5">
                <div className="flex items-center gap-4 mb-4 w-full">
                    <div className="flex items-center gap-2">
                        <input
                            type="checkbox"
                            id="competition-filter"
                            checked={filters.competition}
                            onChange={(e) => setFilters(prev => ({...prev, competition: e.target.checked}))}
                        />
                        <label htmlFor="competition-filter" className="text-gray-700">
                            {t("Show Competition")}
                        </label>
                    </div>
                    <div className="flex items-center justify-between flex-grow">
                        <div className="w-124 flex min-w-0 whitespace-nowrap">
                            <Select
                                isMulti
                                options={countries}
                                value={selectedCountries}
                                onChange={(selected) => setSelectedCountries(selected as CountryOption[])}
                                placeholder={t("Select countries...")}
                                className="text-sm"
                                filterOption={(option, input) => {
                                    if (!input) return true;
                                    const searchInput = input.toLowerCase();
                                    return option.data.name.toLowerCase().includes(searchInput) || 
                                           option.data.value.toLowerCase().includes(searchInput);
                                }}
                                styles={{
                                    control: (base) => ({
                                        ...base,
                                        borderRadius: '0.5rem',
                                        minHeight: '36px',
                                        height: '36px',
                                        minWidth: '400px'  // Double the width
                                    }),
                                    multiValue: (base) => ({
                                        ...base,
                                        borderRadius: '0.25rem',
                                    }),
                                    menu: (base) => ({
                                        ...base,
                                        zIndex: 50,
                                        minWidth: '400px'  // Match control width
                                    }),
                                    container: (base) => ({
                                        ...base,
                                        minWidth: '400px'  // Match control width
                                    })
                                }}
                            />
                        </div>
                        <div className="ml-4 px-3 py-1 bg-gray-100 rounded-md text-sm font-medium">
                            {filteredNotices.length} {t("Matches")}
                        </div>
                    </div>
                </div>

                {/* Cards Grid */}
                <div className="w-full">
                    {isLoading ? (
                        <div className='flex items-center justify-center w-full h-[60vh] text-2xl whitespace-pre text-black'>
                            {t("loading_opportunity_description")}
                        </div>
                    ) : filteredNotices.length <= 0 ? (
                        <div className='flex items-center justify-center w-full h-[60vh] text-2xl text-black'>
                            {t("no_opportunity_description")}
                        </div>
                    ) : (
                        <div className="flex flex-wrap">
                            {filteredNotices.slice((pageCount - 1) * ITEMS_PER_PAGE, pageCount * ITEMS_PER_PAGE).map((notice, index) => (
                                <div
                                    key={notice['publication-number'] || index}
                                    onClick={() => handleTenderClick(notice)}
                                    className={`flex flex-col box-sizing border-box shadow-md border-solid border-[#000000] border-opacity-25 border-[1px] rounded-[10px] ${isChatWindowClosed ? "w-[calc(33.3%-20px)] " : "w-[calc(50%-20px)]"} m-[10px] bg-white relative min-h-[200px]`}
                                >
                                    <div className="absolute top-[10px] right-[10px] z-10">
                                        <div className='w-[24px] h-[24px] relative' 
                                            onClick={async (e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                
                                                Socket.on('filesDownloaded', (message: { data: [] }) => {
                                                    console.log(message.data)
                                                    let newIndex = message.data.length
                                                    // Add any necessary state updates here
                                                });

                                                const data = new FormData();
                                                const id = generateObjectId();
                                                data.append("id", id);
                                                data.append("email", user.sub.email);
                                                data.append("companyId", user.sub.companyId);
                                                data.append("tab", "tab_1");
                                                data.append("socketId", socketId);
                                                data.append("title", notice['title-proc'] || '');
                                                data.append("document", `https://ted.europa.eu/udl?uri=TED:NOTICE:${notice['publication-number']}:TEXT:EN:HTML`);
                                                data.append("customerName", notice['organisation-name-buyer'] && typeof notice['organisation-name-buyer'] === 'object'
                                                    ? Object.values(notice['organisation-name-buyer'])[0]?.[0] || 'Unknown organization'
                                                    : 'Unknown organization');
                                                data.append("competitionDocsUrl", `https://ted.europa.eu/udl?uri=TED:NOTICE:${notice['publication-number']}:TEXT:EN:HTML`);
                                                data.append("date", notice['deadline'] || new Date().toISOString());
                                                data.append("est", notice['total-value'] ? notice['total-value'].toString() + " EUR" : "N/A");
                                                data.append("text", 
                                                    "TenderURL: " + `https://ted.europa.eu/udl?uri=TED:NOTICE:${notice['publication-number']}:TEXT:EN:HTML\n` +
                                                    "TenderText: " + (notice['title-proc'] || '') + "\n" +
                                                    "TenderType: " + (notice['form-type'] || 'tender') + "\n" +
                                                    "CustomerName: " + (notice['organisation-name-buyer'] && typeof notice['organisation-name-buyer'] === 'object'
                                                        ? Object.values(notice['organisation-name-buyer'])[0]?.[0] || 'Unknown organization'
                                                        : 'Unknown organization') + "\n" +
                                                    "TenderDate: " + (notice['deadline'] || new Date().toISOString()) + "\n" +
                                                    "Tender ShortDescription: " + (notice['description-proc'] || '') + "\n"
                                                );

                                                try {
                                                    const result = await axios.post(process.env.REACT_APP_API_URL + "api/createEmptyWorkSpace", data, {
                                                        headers: {
                                                            Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                                                            "Content-Type": "application/json",
                                                        }
                                                    });

                                                    if (result.status === 200) {
                                                        addToWorkSpace({
                                                            id: id,
                                                            tenderText: notice['title-proc'] || '',
                                                            customerName: notice['organisation-name-buyer'] && typeof notice['organisation-name-buyer'] === 'object'
                                                                ? Object.values(notice['organisation-name-buyer'])[0]?.[0] || 'Unknown organization'
                                                                : 'Unknown organization',
                                                            tenderURL: `https://ted.europa.eu/udl?uri=TED:NOTICE:${notice['publication-number']}:TEXT:EN:HTML`,
                                                            competitionDocsUrl: `https://ted.europa.eu/udl?uri=TED:NOTICE:${notice['publication-number']}:TEXT:EN:HTML`,
                                                            tenderDate: notice['deadline'] || new Date().toISOString(),
                                                            date: notice['publication-date'] || new Date().toISOString(),
                                                            tenderType: notice['form-type'] || 'tender',
                                                            shortDescription: notice['description-proc'] || '',
                                                            estimatedValueAmount: notice['total-value'] || 0,
                                                            estimatedValueCurrencyCode: 'EUR'
                                                        }, id, null);
                                                    }
                                                } catch (error) {
                                                    console.error('Error creating workspace:', error);
                                                }
                                            }}
                                            onMouseEnter={() => setHover(notice['publication-number'])}
                                            onMouseLeave={() => setHover(null)}
                                        >
                                            {notice?.isAddedToWorkSpace || clickedHeartIndex.includes(notice['publication-number']) ? (
                                                <ClickedHeart className="w-full h-full cursor-pointer" />
                                            ) : hoverHeartIndex === notice['publication-number'] ? (
                                                <HoverHeart className="w-full h-full cursor-pointer" />
                                            ) : (
                                                <NormalHeart 
                                                    className="w-full h-full cursor-pointer"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        const transformedNotice = {
                                                            id: generateObjectId(),
                                                            tenderText: notice['title-proc'] || '',
                                                            customerName: notice['organisation-name-buyer'] && typeof notice['organisation-name-buyer'] === 'object'
                                                                ? Object.values(notice['organisation-name-buyer'])[0]?.[0] || 'Unknown organization'
                                                                : 'Unknown organization',
                                                            tenderURL: `https://ted.europa.eu/udl?uri=TED:NOTICE:${notice['publication-number']}:TEXT:EN:HTML`,
                                                            tenderType: notice['form-type'] || 'tender',
                                                            tenderDate: notice['deadline'] || new Date().toISOString(),
                                                            shortDescription: notice['description-proc'] || '',
                                                            date: notice['publication-date'] || new Date().toISOString(),
                                                            estimatedValueAmount: notice['total-value'] || 0,
                                                            estimatedValueCurrencyCode: 'EUR',
                                                            type: 'tender',
                                                            status: 'active',
                                                            source: 'TED',
                                                            publication_number: notice['publication-number'],
                                                            competitionDocsUrl: `https://ted.europa.eu/udl?uri=TED:NOTICE:${notice['publication-number']}:TEXT:EN:HTML`,
                                                            cartText: 'View',
                                                            cartTextHover: 'View on TED'
                                                        };
                                                        addToWorkSpace(transformedNotice, transformedNotice.id, null);
                                                    }}
                                                    onMouseEnter={() => setHover(notice['publication-number'])}
                                                />
                                            )}
                                        </div>
                                    </div>

                                    <div className="flex flex-col flex-grow">
                                        <div className="flex items-start">
                                            <div className='flex flex-col text-black pl-[10px] pr-[40px] w-[100%]'>
                                                <div className='relative flex mt-[10px]'>
                                                    <div className="relative w-full">
                                                        <div className='flex flex-col gap-2'>
                                                            <span 
                                                                className="text-[18px] font-bold text-left cursor-pointer leading-tight line-clamp-2"
                                                                onClick={() => handleTenderClick(notice)}
                                                                title={notice['description-proc'] || 'No description available'}
                                                            >
                                                                {notice['title-proc'] || 'No title'}
                                                                <div className="invisible group-hover:visible absolute z-20 w-96 p-4 bg-white text-gray-800 text-sm rounded-lg shadow-xl -top-2 left-full ml-2 border border-gray-200">
                                                                    <div className="font-semibold mb-2">Description</div>
                                                                    <div className="leading-relaxed">
                                                                        {notice['description-proc'] || 'No description available'}
                                                                    </div>
                                                                </div>
                                                            </span>
                                                            <div className='text-[18px] text-left'>
                                                                {notice['organisation-name-buyer'] && typeof notice['organisation-name-buyer'] === 'object'
                                                                    ? Object.values(notice['organisation-name-buyer'])[0]?.[0] ?? 'Unknown organization'
                                                                    : 'Unknown organization'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-auto flex flex-col gap-2 mx-[10px] mb-[10px]">
                                        <div className="flex items-center">
                                            {notice['form-type'] && i18n?.language && (
                                                <>
                                                    {console.log('Form type:', notice['form-type'])}
                                                    {console.log('Mapped type:', mapFormTypeToType(notice['form-type']))}
                                                    {getTypeTag(mapFormTypeToType(notice['form-type']), i18n.language)}
                                                </>
                                            )}
                                        </div>

                                        <div className='flex gap-4 text-left text-black text-xs opacity-80 justify-between mt-2'>
                                            <div className='flex gap-4'>
                                                <div className="flex items-center gap-2">
                                                    <MoneyIcon />
                                                    <div>
                                                        {typeof notice['total-value'] === 'number' 
                                                            ? `€${notice['total-value'].toLocaleString()}`
                                                            : '-'}
                                                    </div>
                                                </div>
                                                <div className="flex items-center gap-2">
                                                    <CalendarIcon />
                                                    <div>{formatShortDate(notice['publication-date'])}</div>
                                                </div>
                                                <div className="flex items-center gap-2">
                                                    <BellIcon />
                                                    <div>{formatShortDate(getFirstDeadline(notice['deadline']))}</div>
                                                </div>
                                            </div>
                                            <div className="flex items-center">
                                                <CountryDisplay countryCode={notice['organisation-country-buyer']} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>

            {/* Pagination Controls */}
            {!isLoading && filteredNotices.length >= ITEMS_PER_PAGE && (
                <div className='flex w-full px-5 h-[50px] text-base justify-end'>
                    <div 
                        className={`text-[20px] font-bold w-[50px] border-gray-300 hover:bg-gray-100 hover:text-gray-700 h-[50px] flex "text-black bg-white" justify-center items-center text-center border rounded-md cursor-pointer`} 
                        onClick={() => {
                            if (pageCount === 1) return;
                            setPageCount(prev => {
                                scrollToTop();
                                return prev - 1;
                            });
                        }}
                    >
                        <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd"></path>
                        </svg>
                    </div>
                    {filteredNotices.map((notice, index) => {
                        return index % ITEMS_PER_PAGE === 0 && (
                            <div 
                                className={`text-[20px] font-bold w-[50px] border-gray-300 hover:bg-gray-100 hover:text-gray-700 h-[50px] flex ${pageCount === index / ITEMS_PER_PAGE + 1 ? "text-white bg-main_color_2" : "text-black bg-white"} justify-center items-center text-center border rounded-md cursor-pointer`}
                                key={index / ITEMS_PER_PAGE}
                                onClick={() => {
                                    setPageCount(prev => {
                                        scrollToTop();
                                        return index / ITEMS_PER_PAGE + 1;
                                    });
                                }}
                            >
                                {index / ITEMS_PER_PAGE + 1}
                            </div>
                        );
                    })}
                    <div 
                        className={`text-[20px] font-bold w-[50px] border-gray-300 hover:bg-gray-100 hover:text-gray-700 h-[50px] flex "text-black bg-white" justify-center items-center text-center border rounded-md cursor-pointer`}
                        onClick={() => {
                            if (pageCount >= Math.ceil(filteredNotices.length / ITEMS_PER_PAGE)) return;
                            setPageCount(prev => {
                                scrollToTop();
                                return prev + 1;
                            });
                        }}
                    >
                        <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd"></path>
                        </svg>
                    </div>
                </div>
            )}

            {/* Modal */}
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                {selectedNotice && (
                    <div className="mt-6 space-y-6">
                        <h2 className="text-2xl font-bold">
                            {selectedNotice['title-proc'] || 'No title'}
                        </h2>
                        
                        <div className="grid grid-cols-2 gap-4">
                            <div className="space-y-4">
                                <div>
                                    <h3 className="font-semibold text-gray-700">Organization</h3>
                                    <p>{selectedNotice['organisation-name-buyer'] && 
                                        typeof selectedNotice['organisation-name-buyer'] === 'object'
                                        ? Object.values(selectedNotice['organisation-name-buyer'])[0]?.[0]
                                        : 'Unknown organization'}</p>
                                </div>
                                
                                <div>
                                    <h3 className="font-semibold text-gray-700">Publication Date</h3>
                                    <p>{formatShortDate(selectedNotice['publication-date'])}</p>
                                </div>

                                <div>
                                    <h3 className="font-semibold text-gray-700">Deadline</h3>
                                    <p>{formatShortDate(getFirstDeadline(selectedNotice['deadline']))}</p>
                                </div>
                            </div>

                            <div className="space-y-4">
                                <div>
                                    <h3 className="font-semibold text-gray-700">Value</h3>
                                    <p>{typeof selectedNotice['total-value'] === 'number' 
                                        ? `€${selectedNotice['total-value'].toLocaleString()}`
                                        : '-'}</p>
                                </div>

                                <div>
                                    <h3 className="font-semibold text-gray-700">Type</h3>
                                    <p>{capitalizeFormType(selectedNotice['form-type'])}</p>
                                    {noticeDetails?.type?.contract_nature && (
                                        <p className="text-gray-600 mt-1">
                                            Contract Nature: {noticeDetails.type.contract_nature}
                                        </p>
                                    )}
                                </div>

                                <div>
                                    <h3 className="font-semibold text-gray-700 text-center">Country</h3>
                                    <div className="flex flex-col items-center gap-2mt-1">
                                        <CountryDisplay countryCode={selectedNotice['organisation-country-buyer']} />
                                        <span>{selectedNotice['organisation-country-buyer']}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <h3 className="font-semibold text-gray-700 mb-2">Description</h3>
                            <p className="text-gray-600 whitespace-pre-wrap">
                                {selectedNotice['description-proc'] || 'No description available'}
                            </p>
                        </div>
{noticeDetails && (
    <div className="mt-6 space-y-6">
        {/* Documents & Links Section */}
        <div className="space-y-4">
            <h3 className="font-semibold text-gray-700 text-left">Documents & Links</h3>
            <div className="flex flex-col gap-3">
                {/* Competition Documents */}
                {noticeDetails.documents?.competition && (
                    <a
                        href={noticeDetails.documents.competition}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:text-blue-800 flex items-center gap-2"
                    >
                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                        </svg>
                        Competition Documents
                    </a>
                )}
            </div>
        </div>

        {/* Criteria Section */}
        {(noticeDetails?.criteria?.award || noticeDetails?.criteria?.selection) && (
            <div className="space-y-4 text-left">
                <h3 className="font-semibold text-gray-700">Criteria</h3>
                {noticeDetails?.criteria?.award && noticeDetails.criteria.award.length > 0 && (
                    <div>
                        <h4 className="font-medium text-gray-600">Award Criteria</h4>
                        <div className="space-y-3 mt-2">
                            {noticeDetails.criteria.award.map((criterion, index) => (
                                <div key={index} className="pl-4 border-l-2 border-blue-200">
                                    <div className="font-medium text-gray-700">
                                        {criterion}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        )}

        {/* Submission Details */}
        {noticeDetails.submission?.language && (
            <div>
                <h3 className="font-semibold text-gray-700 text-left">Submission Details</h3>
                <p className="text-gray-600 text-left">
                    Language: {Array.isArray(noticeDetails.submission.language) 
                        ? noticeDetails.submission.language[0]
                        : noticeDetails.submission.language}
                </p>
            </div>
        )}

        {/* Legal Information */}
        {noticeDetails.legal?.basis && (
            <div>
                <h3 className="font-semibold text-gray-700 text-left">Legal Basis</h3>
                <p className="text-gray-600 text-left">
                    {Array.isArray(noticeDetails.legal.basis)
                        ? noticeDetails.legal.basis.join(', ')
                        : noticeDetails.legal.basis}
                </p>
            </div>
        )}

        {/* Classifications Section */}
        {(noticeDetails?.classification?.main?.code || 
            noticeDetails?.classification?.additional) && (
            <div className="space-y-4 text-left">
                <h3 className="font-semibold text-gray-700">Classifications</h3>
                
                {/* CPV Codes */}
                {noticeDetails?.classification?.main?.code && (
                    <div>
                        <h4 className="font-medium text-gray-600">Main CPV</h4>
                        <div className="flex flex-wrap gap-2 mt-1">
                            {noticeDetails.classification.main.code.map((code, index) => (
                                <span key={index} className="bg-blue-50 text-blue-700 px-2 py-1 rounded text-sm">
                                    {code} {noticeDetails.classification.main?.description && 
                                        `- ${noticeDetails.classification.main.description}`}
                                </span>
                            ))}
                        </div>
                    </div>
                )}
                
                {/* Additional CPV Codes */}
                {noticeDetails.classification.additional && (
                    <div className="mt-3">
                        <h4 className="font-medium text-gray-600">Additional CPV</h4>
                        <div className="flex flex-wrap gap-2 mt-1">
                            {noticeDetails.classification.additional.map((code, index) => (
                                <span key={index} className="bg-gray-50 text-gray-700 px-2 py-1 rounded text-sm">
                                    {code}
                                </span>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        )}

        {/* Lots Section */}
        {noticeDetails?.lots?.titles && Object.keys(noticeDetails.lots.titles).length > 0 && (
            <div className="space-y-4 text-left">
                <h3 className="font-semibold text-gray-700">Lots</h3>
                {Object.entries(noticeDetails.lots.titles).map(([lang, titles]) => (
                    <div key={lang} className="space-y-2">
                        <h4 className="font-medium text-gray-600 uppercase">{lang}</h4>
                        <div className="space-y-2">
                            {Array.isArray(titles) && titles.map((title, index) => (
                                <div 
                                    key={index} 
                                    className="p-3 bg-gray-50 rounded-lg border border-gray-200"
                                >
                                    <div className="flex justify-between items-start">
                                        <div>
                                            <span className="text-gray-700">
                                                {noticeDetails.lots?.codes?.[index] || `Lot ${index + 1}`}:
                                            </span>{' '}
                                            {title}
                                        </div>
                                        {noticeDetails.lots?.durations?.[index] && (
                                            <div className="text-sm text-gray-600 flex items-center gap-1">
                                                <svg 
                                                    className="w-4 h-4" 
                                                    fill="none" 
                                                    stroke="currentColor" 
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path 
                                                        strokeLinecap="round" 
                                                        strokeLinejoin="round" 
                                                        strokeWidth="1.5" 
                                                        d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" 
                                                    />
                                                </svg>
                                                Duration: {noticeDetails.lots.durations[index].value} {' '}
                                                {noticeDetails.lots.durations[index].unit.toLowerCase()}
                                                {Number(noticeDetails.lots.durations[index].value) > 1 ? 's' : ''}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        )}
    </div>
)}

                        <div className="mt-6 flex justify-end">
                            <button
                                onClick={() => window.open(`https://ted.europa.eu/udl?uri=TED:NOTICE:${selectedNotice['publication-number']}:TEXT:EN:HTML`, '_blank')}
                                className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors"
                            >
                                View on TED
                            </button>
                        </div>
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default TedOpportunities; 