import React, { useEffect, useState, TouchEvent, TouchList, Fragment } from 'react'
import "../../../App.css"
import $ from 'jquery'
import { parseJWT } from '../../../utils';
const { default: axios } = require('axios');
import { ToastContainer, toast, Flip } from "react-toastify";
import { Editor } from './Editor'
import { Insights } from "./Insights"
import { AgentWin } from './AgentWin'
import { useTranslation } from 'react-i18next';
import { Opportunities } from './Opportunities';
import i18n from '../../../i18n';
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/outline";
import { v4 as uuidv4 } from 'uuid';
import ConfirmModal from './ConfirmModal';
import { InsightModal } from '../../../Components/Shared/InsightModal';
import { Compare } from "./Compare"
import DropDown from './DropDown';

interface RightComponentProps {
    document: string,
    openedDocument: string,
    documentUpdated: boolean,
    styleCenter: any,
    documentAction: string,
    setDocumentUpdated: any,
    promptList: any,
    setPromptList: React.Dispatch<React.SetStateAction<any[]>>,
    tender_tab: any
    tender_title: any
    selectedDocumentList: any[]
    promptDataFetch: any
    summaries: any[]
    handleDocumentClick: any
    currentSlide: number
    setCurrentSlide: any
    selectedFolderID: any
    previousOpenedDocument: string
    opportunities: any[]
    isChatWindowClosed: boolean
    opportuniteisDataFetch: any
    setTenderList: any
    selectedTab: any
    setOpenedStatus: any
    handleSelectedFolder: any
    cpvCodes: any[]
    summaryDataFetch: any
    setSettingFinished: any
    isSettingFinished: any
    setLoadingStatue: any
    tenderList: any
    handleSelectedTab: any
    tender_id: any
    setTabName: any
    setSummaryList: any
    dateType: any
    setDateType: any
    setCurrentDate: any
    currentDate: any
    cpvCodesFetch: any
    companyKnowledgeBaseId: string
    currentPage: number
    setCurrentPage: (page: number) => void
    dbSaveSuccess: () => void
    isDoffin: boolean
}


export const RightComponent: React.FC<RightComponentProps> = ({ setSummaryList, document, openedDocument, documentUpdated, styleCenter, documentAction, setDocumentUpdated, promptList, setPromptList, tender_tab, tender_title, selectedDocumentList, promptDataFetch, summaries, handleDocumentClick, currentSlide, setCurrentSlide, selectedFolderID, previousOpenedDocument, opportunities, isChatWindowClosed, opportuniteisDataFetch, setTenderList, selectedTab, setOpenedStatus, handleSelectedFolder, cpvCodes, summaryDataFetch, setSettingFinished, isSettingFinished, setLoadingStatue, tenderList, handleSelectedTab, tender_id, setTabName, dateType, setDateType, setCurrentDate, currentDate, cpvCodesFetch, companyKnowledgeBaseId, currentPage, setCurrentPage, dbSaveSuccess, isDoffin }) => {
    let user = localStorage.getItem("tender_auth") ? parseJWT(localStorage.getItem("tender_auth")) : null;
    const [selectedText, setSelectedText] = useState('')
    const [message, setMessage] = useState<any>('')
    const [autoFilling, setAutoFilling] = useState<any>(false)
    const [isGettingAnswer, setGettingAnswer] = useState(false)
    const [tabData, setTabData] = useState<any[]>([]);
    const [refDocs, setRefDocs] = useState<any[]>([]);
    const [selected, setSelected] = useState("");
    const [query, setQuery] = useState("");
    const { t } = useTranslation();
    const [complianceData, setComplianceData] = useState<any[]>([]);
    const [selectedTableData, setSelectedTableData] = useState<any>({});
    const [compilanceSaveClicked, setCompilanceSaveClicked] = useState(false);
    const [isReferenceDocument, setIsReferenceDocument] = useState(true)
    const [isFromModal, setIsFromModal] = useState(false)
    const [keyWords, setKeyWords] = useState("")
    const [socketId, setSocketId] = useState<any>("")
    const [slideDirection, setSlideDirection] = useState('right');
    const [isInEditor, setIsInEditor] = useState(false);

    useEffect(() => {
        async function saveKeyWords() {
            try {
                const data = new FormData();
                data.append("email", user.sub.email);
                data.append("keyWords", keyWords);
                data.append("document", openedDocument);
                const result = await axios.post(process.env.REACT_APP_API_URL + "api/saveKeyWords", data, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                        "Content-Type": "application/json",
                    }
                });
            } catch (error) {

            }

        }
        if (keyWords) {
            saveKeyWords()
        }
    }, [keyWords])

    const filetedDoc: any[] =
        query === ""
            ? refDocs
            : refDocs.filter((doc) =>
                doc.toLowerCase()
                    .replace(/\s+/g, "")
                    .includes(query.toLowerCase().replace(/\s+/g, ""))
            );
    const getAIFeedback = async () => {
        setGettingAnswer(true);
        await new Promise((resolve: any) => {
            resolve();
        });
        const language = i18n.language ? i18n.language : "en"

        let question = selected !== '' ? "By referencing " + selected + ", Please try to make improvement of following text.\n" + selectedText : "Please try to make improvement of following text.\n" + selectedText
        if (language == 'no') {
            question = selected !== '' ? "Ved å referere " + selected + ", Prøv å forbedre følgende tekst.\n" + selectedText : "Prøv å forbedre følgende tekst.\n" + selectedText
        }
        if (!isReferenceDocument) {
            question = "By referencing following keywords, " + keyWords + " Please try to make improvement of following text without making false statements.\n" + selectedText
            if (language == 'no') {
                question = "Ved å referere til følgende nøkkelord, " + keyWords + " Vennligst prøv å forbedre følgende tekst uten å komme med falske påstander.\n" + selectedText
            }
        }
        let params = {
            email: user.sub.email,
            companyId: user.sub.companyId,
            question: question,
            openedDocument: selected,
            selectedDocumentList: [selected],
        }
        const response: any = await fetch(process.env.REACT_APP_API_URL + "api/getAnswer", {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                'Content-Type': 'application/json',
            }
        });
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let done = false;
        let messageValue = ''
        while (!done) {
            const { value, done: doneReading } = await reader.read();
            done = doneReading;
            const chunkValue = decoder.decode(value);
            var regex = /([^:]*)json_data:(\{.*?\})/g;
            var match = regex.exec(chunkValue);
            if (match && match.length >= 3) {
                var beforeText = match[1];
                if (beforeText) {
                    messageValue = messageValue + beforeText
                    const parser = new DOMParser();
                    const htmlDoc = parser.parseFromString(messageValue, 'text/html');
                    const extractedText = htmlDoc.documentElement.textContent;
                    setMessage(extractedText)
                }
                var jsonData = match[2];
            } else {
                messageValue = messageValue + chunkValue
                if (!chunkValue.includes("</")) {
                    const parser = new DOMParser();
                    const htmlDoc = parser.parseFromString(messageValue, 'text/html');
                    const extractedText = htmlDoc.documentElement.textContent;
                    setMessage(extractedText)
                }
            }
        }
        setGettingAnswer(false)
    }
    const aiFeedbackGetAnswer = async (selectedText: string, pOpenedDocument: string) => {
        try {
            const data = new FormData();
            data.append("email", user.sub.email);
            data.append("keyWords", keyWords);
            data.append("document", openedDocument);
            const result = await axios.post(process.env.REACT_APP_API_URL + "api/getKeywords", data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                    "Content-Type": "application/json",
                }
            });
            setKeyWords(result?.data?.data)

        } catch (error) {

        }
        let refDocs: any[] = []
        tabData.forEach((item: any) => {
            refDocs.push(...item?.documents);
        });
        setRefDocs(refDocs)
        setSelected(refDocs[0])
        setSelectedText(selectedText)
        setMessage('')
    }
    const handleDelete = async (id: string) => {
        try {
            const data = new FormData();
            data.append("id", id);
            data.append("selectedFolderID", selectedFolderID);
            
            const result = await axios.post(process.env.REACT_APP_API_URL + "api/deletePrompt", data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                    "Content-Type": "application/json",
                }
            });
            
            if (result.status === 200) {
                toast.success(t("Successfully Deleted!"), {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: 0,
                    toastId: "my_toast",
                });
                promptDataFetch();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleEditPrompt = (prompt: any) => {
        console.log("Edit prompt:", prompt);
    };

    const updateDocumentText = (text: string) => {
        let documentObject: any = $('#docx_container');
        if (documentObject[0]?.ej2_instances[0]?.documentEditor) {
            let container: any = documentObject[0]?.ej2_instances[0]?.documentEditor
            container.editor.deleteSelectedContents(container.selection, false, false)
            container.editor.insertText(text, true)
        }
    }
    const [slidecount, setSlideCount] = useState(currentSlide);
    useEffect(() => {
        setSlideCount(currentSlide)
    }, [currentSlide])
    const [addedState, setAddedState] = useState(false);
    const [documentUrl, setDocumentUrl] = useState(null);
    const [documentsCount, setDocumentsCount] = useState(0);
    const [clickedHeartIndex, setClickedHeartIndex] = useState<any>([]);
    const [hoverHeartIndex, setHover] = useState(-1);

    const refreshData = () => {
        setAddedState(false);
        opportuniteisDataFetch(currentDate);
        setClickedHeartIndex([]);
        setHover(-1);
    }

    const determineSlideDirection = (newSlide: number, currentSlide: number) => {
        if (currentSlide === 3 && newSlide === 0) {
            return 'right';
        }
        if (currentSlide === 0 && newSlide === 3) {
            return 'left';
        }
        return newSlide > currentSlide ? 'right' : 'left';
    };

    const handleSlideChange = (newSlide: number) => {
        if (documentAction === "direct-open") {
            setCurrentSlide(newSlide);
            return;
        }
        
        const direction = determineSlideDirection(newSlide, currentSlide);
        setSlideDirection(direction);
        setCurrentSlide(newSlide);
    };

    const getSlideClasses = (slideIndex: number) => {
        if (isInEditor && currentSlide === 3) {
            return slideIndex === currentSlide ? 'slide slide-active' : 'slide';
        }
        
        if (slideIndex === currentSlide) {
            return 'slide slide-active';
        }
        
        if (slideDirection === 'right') {
            return `slide ${slideIndex > currentSlide ? 'slide-enter-right' : 'slide-exit-left'}`;
        } else {
            return `slide ${slideIndex < currentSlide ? 'slide-enter-left' : 'slide-exit-right'}`;
        }
    };

    useEffect(() => {
        if (documentAction === "direct-open") {
            setIsInEditor(true);
        }
    }, [documentAction]);

    return (
        <>
            <div className="md:w-full lg:w-[50%] text-center bg-bg_body rounded-xl relative" style={styleCenter}>
                {addedState ? <ConfirmModal setTenderList={setTenderList} tenderList={tenderList} selectedFolderID={selectedFolderID} setIsFromModal={setIsFromModal} setCurrentSlide={setCurrentSlide} documentsCount={documentsCount} url={documentUrl} refreshData={refreshData} promptDataFetch={promptDataFetch}></ConfirmModal> : <></>}

                <div className={`slide-container ${documentAction === "direct-open" ? 'direct-open' : ''}`}>
                    <div className={`${getSlideClasses(0)} bg-bg_body`}>
                        <Opportunities setSocketId={setSocketId} cpvCodesFetch={cpvCodesFetch} hoverHeartIndex={hoverHeartIndex} setHover={setHover} clickedHeartIndex={clickedHeartIndex} setClickedHeartIndex={setClickedHeartIndex} currentDate={currentDate} setCurrentDate={setCurrentDate} setDocumentUrl={setDocumentUrl} setDocumentsCount={setDocumentsCount} setAddedState={setAddedState} handleSelectedTab={handleSelectedTab} setIsFromModal={setIsFromModal} setCurrentSlide={setCurrentSlide} opportunities={opportunities} isChatWindowClosed={isChatWindowClosed} opportuniteisDataFetch={opportuniteisDataFetch} selectedFolderID={selectedFolderID} setTenderList={setTenderList} selectedTab={selectedTab} setOpenedStatus={setOpenedStatus} handleSelectedFolder={handleSelectedFolder} cpvCodes={cpvCodes} promptDataFetch={promptDataFetch} summaryDataFetch={summaryDataFetch} setSettingFinished={setSettingFinished} isSettingFinished={isSettingFinished} setLoadingStatue={setLoadingStatue} tenderList={tenderList} dateType={dateType} setDateType={setDateType} isDoffin={isDoffin}></Opportunities>
                    </div>
                    <div className={`${getSlideClasses(1)} bg-bg_body`}>
                        <Insights 
                            socketId={socketId} 
                            setAutoFilling={setAutoFilling} 
                            setCurrentSlide={setCurrentSlide} 
                            setSummaryList={setSummaryList} 
                            setTabName={setTabName} 
                            selectedTab={selectedTab} 
                            setTenderList={setTenderList} 
                            setIsFromModal={setIsFromModal} 
                            isFromModal={isFromModal} 
                            openedDocument={openedDocument} 
                            promptList={promptList} 
                            selectedDocumentList={selectedDocumentList} 
                            handleEditPrompt={handleEditPrompt} 
                            selectedFolderID={selectedFolderID} 
                            promptDataFetch={promptDataFetch} 
                            summaries={summaries} 
                            handleDocumentClick={handleDocumentClick} 
                            tenderList={tenderList} 
                            setOpenedStatus={setOpenedStatus} 
                            handleSelectedFolder={handleSelectedFolder} 
                            companyKnowledgeBaseId={companyKnowledgeBaseId}
                            dbSaveSuccess={dbSaveSuccess}
                        />
                    </div>
                    <div className={`${getSlideClasses(2)} bg-bg_body`}>
                        <Compare 
                            setCurrentSlide={setCurrentSlide}
                            promptList={promptList}
                            selectedFolderID={selectedFolderID}
                            selectedDocumentList={selectedDocumentList}
                            promptDataFetch={promptDataFetch}
                            tenderList={tenderList}
                            openedDocument={openedDocument}
                            handleEditPrompt={handleEditPrompt}
                            handleDocumentClick={handleDocumentClick}
                            isFromModal={isFromModal}
                            setIsFromModal={setIsFromModal}
                            setOpenedStatus={setOpenedStatus}
                            handleSelectedFolder={handleSelectedFolder}
                            selectedTab={selectedTab}
                            setTabName={setTabName}
                            setAutoFilling={setAutoFilling}
                            socketId={socketId}
                            companyKnowledgeBaseId={companyKnowledgeBaseId}
                            setTenderList={setTenderList}
                        />
                    </div>
                    <div className={`${getSlideClasses(3)} bg-bg_body`}>
                        <Editor setAutoFilling={setAutoFilling} autoFilling={autoFilling} document={document} openedDocument={openedDocument} documentUpdated={documentUpdated} styleCenter={styleCenter} currentSlide={currentSlide} documentAction={documentAction} setDocumentUpdated={(val: boolean) => setDocumentUpdated(val)} tender_tab={tender_tab} tender_title={tender_title} aiFeedbackGetAnswer={(text: string, pDocument: string) => {
                            aiFeedbackGetAnswer(text, pDocument)
                        }} previousOpenedDocument={previousOpenedDocument} selectedDocumentList={selectedDocumentList} complianceData={complianceData} setComplianceData={setComplianceData} selectedTableData={selectedTableData} setSelectedTableData={setSelectedTableData} setCurrentSlide={setCurrentSlide} compilanceSaveClicked={compilanceSaveClicked} setCompilanceSaveClicked={setCompilanceSaveClicked} tender_id={tender_id} companyKnowledgeBaseId={companyKnowledgeBaseId}></Editor>
                    </div>
                    <div className={`${getSlideClasses(4)} bg-bg_body`}>
                        <AgentWin complianceData={complianceData} setComplianceData={setComplianceData} selectedTableData={selectedTableData} setSelectedTableData={setSelectedTableData} setCompilanceSaveClicked={setCompilanceSaveClicked} setCurrentSlide={setCurrentSlide}></AgentWin>
                    </div>
                </div>

                <div className="absolute bottom-5 left-0 right-0 pointer-events-auto" style={{ zIndex: 10000 }}>
                    <div className="flex justify-center items-center gap-2">
                        {[1, 2, 3, 4, 5].map((i) => (
                            <img
                                key={i-1}
                                src={process.env.PUBLIC_URL + `/img/dot${i}${i-1 === currentSlide ? `-${i}` : ""}.png`}
                                width={35}
                                height={35}
                                className="cursor-pointer hover:opacity-80 transition-opacity"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    
                                    const newSlide = i-1;
                                    const direction = determineSlideDirection(newSlide, currentSlide);
                                    setSlideDirection(direction);
                                    
                                    if (newSlide === 3) { // Going to editor
                                        setIsInEditor(true);
                                        handleDocumentClick(openedDocument, "direct-open", tender_title, tender_id);
                                    } else { // Any other slide
                                        setIsInEditor(false);
                                        if (currentSlide === 3) { // If leaving editor
                                            handleDocumentClick("", "", "", "");
                                        }
                                    }
                                    
                                    handleSlideChange(newSlide);
                                }}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}
interface TagInputProps {
    keyWords: string
    setKeyWords: any;
}


const TagInput: React.FC<TagInputProps> = ({ keyWords, setKeyWords }) => {
    const [tags, setTags] = useState<string[]>([]);
    const [inputValue, setInputValue] = useState('');

    // Split the keywords into individual tags when the component mounts or when keywords change
    useEffect(() => {
        const initialTags = keyWords.split(',').map((tag) => tag.trim()).filter((tag) => tag !== '');
        setTags(initialTags);
    }, [keyWords]); // Ensure tags are updated if keywords prop changes

    const updateKeywords = (updatedTags: string[]) => {
        setTags(updatedTags);
        const keywordsString = updatedTags.join(','); // Join the updated tags into a comma-separated string
        setKeyWords(keywordsString); // Call setKeywords with the updated string
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' || e.key === ',') {
            e.preventDefault();
            addTag(inputValue.trim());
        } else if (e.key === 'Backspace' && inputValue === '' && tags.length > 0) {
            removeTag(tags.length - 1);
        }
    };

    const addTag = (text: string) => {
        if (text && !tags.includes(text)) {
            const updatedTags = [...tags, text];
            updateKeywords(updatedTags); // Update the tag list and notify the parent component
            setInputValue(''); // Clear the input field after adding a tag
        }
    };

    const removeTag = (index: number) => {
        const updatedTags = tags.filter((_, i) => i !== index);
        updateKeywords(updatedTags); // Update the tag list and notify the parent component
    };

    return (
        <div className="flex flex-wrap items-center gap-2 px-4 py-3 border rounded">
            {tags.map((tag, index) => (
                <span key={index} className="bg-[#243C58] rounded-[10px] flex items-center px-2 py-1 text-white">
                    {tag}
                    <button
                        type="button"
                        className="ml-1 text-white"
                        onClick={() => removeTag(index)}
                    >
                        &times;
                    </button>
                </span>
            ))}
            <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                className="flex-grow text-black outline-none"
                placeholder="Type to add new"
            />
        </div>
    );
};
