import React, { useEffect, useState, useRef } from 'react';
import i18n from '../../../i18n';
const { default: axios } = require('axios');
import { CreateNewDocumentModal } from '../LeftComponent/CreateNewDocumentModal';
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import { parseJWT } from '../../../utils';
type DropdownProps = {
    tenderList: any;
    documents: string[];
    selectedFolderID: string
    description: string
    getDocumentsForSubmission: any
    clearDropdownData: any
    dropdownIndex: any
    dropdownDoc: any
    index: number
    dbSaveSuccess: () => void;
    companyKnowledgeBaseId: any,
};

const Dropdown: React.FC<DropdownProps> = ({ tenderList, documents, selectedFolderID, description, getDocumentsForSubmission, clearDropdownData, dropdownIndex, dropdownDoc, index, dbSaveSuccess, companyKnowledgeBaseId }) => {
    const [selectedDocument, setSelectedDocument] = useState<string | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const language = i18n.language || "en"
    const dropdownRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const listRef = useRef<HTMLUListElement>(null);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [isSaveStarted, setIsSaveStarted] = useState(false);
    const { t } = useTranslation();
    let user = parseJWT(localStorage.getItem("tender_auth"));

    useEffect(() => {
        if (dropdownDoc != null && index === dropdownIndex) {
            setSelectedDocument(dropdownDoc)
        }
    }, [dropdownDoc])

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
                clearDropdownData();
                setSearchTerm('');
                setHighlightedIndex(-1);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [clearDropdownData]);

    const handleCreateDocument = async (documentName: string, responseFromAI: string) => {
        const data = new FormData();
        data.append("id", selectedFolderID);
        data.append("email", user?.sub?.email);
        const fileName = !documentName.toLowerCase().endsWith('.docx') ? `${documentName}.docx` : documentName;
        data.append("tab", tenderList.find(tender => tender.id === selectedFolderID)?.tab || '');
        data.append("folder", tenderList.find(tender => tender.id === selectedFolderID)?.title || '');
        data.append("name", fileName);
        data.append("context", responseFromAI);
        data.append("description", description);
        data.append("isNewDocument", "true");

        try {
            const result = await axios.post(process.env.REACT_APP_API_URL + "api/createNewDocument", data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                    "Content-Type": "application/json",
                }
            });
            if (result.status === 200) {
                toast.success(t("Document Created!"), {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: 0,
                    toastId: "my_toast",
                });

                // Save the new document to the submission with unique description
                const formData: any = new FormData();
                formData.append('tender_id', selectedFolderID);
                formData.append('doc_id', fileName);
                formData.append('description', description);
                formData.append('status', 'Uncomplete');
                formData.append('isNewDocument', 'true');
                
                await axios.post(
                    process.env.REACT_APP_API_URL + "api/saveChangesSubmission", 
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                            "Content-Type": "application/json",
                        }
                    }
                );

                dbSaveSuccess();
                await getDocumentsForSubmission("normal");
                setShowCreateModal(false);
            }
        } catch (error) {
            console.error(error);
            toast.error(t("Error creating document"));
        }
    };

    const handleSelect = async (document: string) => {
        if (document === "Create New Document") {
            setShowCreateModal(true);
        } else {
            setSelectedDocument(document);
            setIsOpen(false);
            clearDropdownData();
            setSearchTerm('');
            setHighlightedIndex(-1);
            
            if (document !== 'Select a document' && document !== 'Velg et dokument') {
                const formData: any = new FormData();
                formData.append('tender_id', selectedFolderID);
                formData.append('doc_id', document);
                formData.append('description', description);
                formData.append('status', 'Uncomplete');
                
                try {
                    const result = await axios.post(
                        process.env.REACT_APP_API_URL + "api/saveChangesSubmission", 
                        formData,
                        {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                                "Content-Type": "application/json",
                            }
                        }
                    );
                    
                    if (result.status === 200) {
                        await getDocumentsForSubmission("normal");
                    }
                } catch (error) {
                    console.error('Error saving changes:', error);
                }
            }
        }
    };

    // Split into two separate handlers
    const handleButtonKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
        if (!isOpen) {
            setIsOpen(true);
            if (e.key.length === 1) { // Only handle printable characters
                setSearchTerm(e.key);
            }
        }
    };

    const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const filteredDocs = documents?.filter(doc => 
            doc.toLowerCase().includes(searchTerm.toLowerCase())
        ) || [];

        switch (e.key) {
            case 'ArrowDown':
                e.preventDefault();
                setHighlightedIndex(prev => 
                    prev < filteredDocs.length - 1 ? prev + 1 : prev
                );
                break;
            case 'ArrowUp':
                e.preventDefault();
                setHighlightedIndex(prev => prev > 0 ? prev - 1 : 0);
                break;
            case 'Enter':
                e.preventDefault();
                if (highlightedIndex >= 0 && filteredDocs[highlightedIndex]) {
                    handleSelect(filteredDocs[highlightedIndex]);
                }
                break;
            case 'Escape':
                setIsOpen(false);
                setSearchTerm('');
                setHighlightedIndex(-1);
                break;
        }

        // Scroll highlighted item into view
        if (highlightedIndex >= 0 && listRef.current) {
            const highlightedElement = listRef.current.children[highlightedIndex] as HTMLElement;
            if (highlightedElement) {
                highlightedElement.scrollIntoView({ block: 'nearest' });
            }
        }
    };

    // Filter documents based on search, and remove "Create New Document" from the filtered list
    const filteredDocuments = documents?.filter(doc => 
        doc !== "Create New Document" && doc.toLowerCase().includes(searchTerm.toLowerCase())
    ) || [];

    return (
        <div className="relative inline-block w-full" ref={dropdownRef}>
            <button
                ref={buttonRef}
                onClick={() => setIsOpen(!isOpen)}
                onKeyDown={handleButtonKeyDown}
                className="w-full p-2 text-black bg-white-600 rounded-lg shadow no-focus-visible text-left"
            >
                {searchTerm || selectedDocument || t('Choose a file')}
            </button>

            {isOpen && (
                <div className="absolute z-10 w-full mt-2 bg-white border border-gray-300 rounded-lg shadow-lg">
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onKeyDown={handleInputKeyDown}
                        placeholder={t('Search documents...')}
                        className="w-full p-2 border-b border-gray-300 rounded-t-lg no-focus-visible"
                        autoFocus
                    />
                    <ul ref={listRef} className="max-h-[250px] overflow-y-auto safari-scroll">
                        <li
                            onClick={() => handleSelect("Create New Document")}
                            className="px-4 py-2 cursor-pointer hover:bg-blue-50"
                        >
                            {t("Create New Document")}
                        </li>
                        {filteredDocuments.map((document, index) => (
                            <li
                                key={index}
                                onClick={() => handleSelect(document)}
                                className={`px-4 py-2 cursor-pointer ${
                                    index === highlightedIndex ? 'bg-blue-100' : 'hover:bg-blue-50'
                                }`}
                            >
                                {document}
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            <CreateNewDocumentModal 
                tenderList={tenderList}
                showModal={showCreateModal}
                setShowModal={setShowCreateModal}
                handleCreateDocument={handleCreateDocument}
                isSaveStarted={isSaveStarted}
                companyKnowledgeBaseId={companyKnowledgeBaseId}
                selectedFolderID={selectedFolderID}
                description={description}
            />
        </div>
    );
};

export default Dropdown;
