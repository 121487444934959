import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckedIcon } from "../../../assets/svg/checked.svg"
import { ReactComponent as UncheckedIcon } from "../../../assets/svg/unchecked.svg";
import { LoadingBar } from '../../LoadingBar';
import { toast } from "react-toastify";

interface CreateDocumentModalProps {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
    handleSaveNewDocument: (documentName: string) => void;
    handleImportWebsite: (urls: string, frequency: number, createSummary: boolean) => void;
    isSaveStarted: boolean;
    onDocumentCreated?: () => void;
}

export const CreateDocumentModal: React.FC<CreateDocumentModalProps> = ({
    showModal,
    setShowModal,
    handleSaveNewDocument,
    handleImportWebsite,
    isSaveStarted,
    onDocumentCreated
}) => {
    const { t } = useTranslation();
    const [selectedModalItemIndex, setModalItemIndex] = useState(0);
    const [selectedFrequencyIndex, setSelectedFrequencyIndex] = useState(0);
    const [isSummaryCreate, setIsSummaryCreate] = useState(false);
    const [documentOrWebsiteURL, setDocumentOrWebsiteURL] = useState("");

    if (!showModal) return null;

    const normalizeUrl = (url: string): string => {
        let normalizedUrl = url.trim();
        
        // Fjern mellomrom og linjeskift
        normalizedUrl = normalizedUrl.replace(/\s+/g, '');
        
        // Hvis URL ikke starter med http:// eller https://, legg til https://
        if (!normalizedUrl.match(/^https?:\/\//i)) {
            normalizedUrl = 'https://' + normalizedUrl;
        }
        
        // Fjern trailing slash
        normalizedUrl = normalizedUrl.replace(/\/+$/, '');
        
        // Håndter www. varianter
        if (!normalizedUrl.includes('www.') && !normalizedUrl.match(/^https?:\/\/[^.]+\.[^.]+\./)) {
            normalizedUrl = normalizedUrl.replace(/^(https?:\/\/)/, '$1www.');
        }
        
        return normalizedUrl;
    };

    const handleSave = async () => {
        try {
            if (selectedModalItemIndex === 0) {
                // Legg til .docx hvis filnavn ikke har en endelse
                let fileName = documentOrWebsiteURL;
                if (!fileName.match(/\.(docx|pdf|xlsx)$/i)) {
                    fileName = `${fileName}.docx`;
                }
                await handleSaveNewDocument(fileName);
            } else {
                const urls = documentOrWebsiteURL
                    .split(',')
                    .map(url => normalizeUrl(url))
                    .filter(url => url.length > 0)
                    .join(',');
                    
                await handleImportWebsite(urls, selectedFrequencyIndex, isSummaryCreate);
            }
            setDocumentOrWebsiteURL("");
            setShowModal(false);
            
            if (onDocumentCreated) {
                onDocumentCreated();
            }
        } catch (error) {
            toast.error(t("Error saving changes"));
        }
    };

    return (
        <>
            <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
                <div className="relative w-[360px] my-6 mx-auto md:w-[50%]">
                    <div className="relative flex flex-col w-full border-0 rounded-lg shadow-lg outline-none bg-main_bg focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid rounded-t border-slate-200">
                            <h3 className="text-3xl font-semibold text-black">
                                {t('Create document or import websites')}
                            </h3>
                        </div>
                        <div>
                            <div className="relative flex-auto p-4">
                                <div className='flex text-black text-[16px] mb-[12px]' onClick={() => { setModalItemIndex(0) }}>
                                    {selectedModalItemIndex === 0 ? <CheckedIcon></CheckedIcon> : <UncheckedIcon></UncheckedIcon>}
                                    <p className='ml-[5px]'>{t('New_Document')}</p>
                                </div>
                                <div className='flex text-black text-[16px] mb-[12px]' onClick={() => { setModalItemIndex(1) }}>
                                    {selectedModalItemIndex === 1 ? <CheckedIcon></CheckedIcon> : <UncheckedIcon></UncheckedIcon>}
                                    <p className='ml-[5px]'>{t('Import Website(s)')}</p>
                                </div>
                                <textarea 
                                    rows={selectedModalItemIndex === 0 ? 3 : 6} 
                                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" 
                                    placeholder={selectedModalItemIndex === 0 ? t("Write document name here.") : t("Specify website urls, comma seperated.")} 
                                    onChange={(e) => setDocumentOrWebsiteURL(e.target.value)} 
                                    value={documentOrWebsiteURL}
                                />
                                {selectedModalItemIndex === 1 && (
                                    <>
                                        <div className='flex text-black text-[16px] mt-[12px]'>
                                            <p className='font-medium'>{t('Frequency for checking for updates:')}</p>
                                        </div>
                                        <div className='flex text-black text-[16px] mt-[8px]' onClick={() => { setSelectedFrequencyIndex(0) }}>
                                            {selectedFrequencyIndex === 0 ? <CheckedIcon></CheckedIcon> : <UncheckedIcon></UncheckedIcon>}
                                            <p className='ml-[5px]'>{t("Daily - at 08:00 AM")}</p>
                                        </div>
                                        <div className='flex text-black text-[16px] mt-[8px]' onClick={() => { setSelectedFrequencyIndex(1) }}>
                                            {selectedFrequencyIndex === 1 ? <CheckedIcon></CheckedIcon> : <UncheckedIcon></UncheckedIcon>}
                                            <p className='ml-[5px]'>{t("Weekly - Mondays at 08:00 AM")}</p>
                                        </div>
                                        <div className="border-t border-solid border-slate-200 mt-4">
                                            <div className='flex text-black text-[16px] mb-[12px]'>
                                                <p className='font-medium'>{t("Options:")}</p>
                                            </div>
                                            <div className='flex text-black text-[16px] mt-[8px]' onClick={() => { setIsSummaryCreate(!isSummaryCreate) }}>
                                                {isSummaryCreate ? <CheckedIcon></CheckedIcon> : <UncheckedIcon></UncheckedIcon>}
                                                <p className='ml-[5px]'>{t("Create auto summary")}</p>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-slate-200">
                            <button
                                className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                                onClick={() => setShowModal(false)}
                            >
                                {t("Close")}
                            </button>
                            {isSaveStarted ? (
                                <LoadingBar />
                            ) : (
                                <button
                                    className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-primary_button hover:bg-primary_button_hover hover:shadow-lg focus:outline-none"
                                    onClick={handleSave}
                                >
                                    {t("Save Changes")}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </>
    );
}; 