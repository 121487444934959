import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingBar } from '../../LoadingBar';
import { toast } from "react-toastify";
import { MentionsInput, Mention, MentionsInputStyle, SuggestionDataItem } from 'react-mentions';
import { useFetchMentions } from '../../../utils/mentions';
import { extractDocumentNames } from '../../../utils/mentions';
import { parseJWT } from '../../../utils';

interface CustomSuggestionDataItem extends SuggestionDataItem {
    icon?: string;
    type?: string;
    tender_id?: string;
    documents?: string[];
    tab?: string;
}
interface CreateNewDocumentModalProps {
    tenderList: any;
    showModal: boolean;
    setShowModal: (show: boolean) => void;
    handleCreateDocument: (documentName: string, aiInstructions: string) => Promise<void>;
    isSaveStarted: boolean;
    selectedFolderID: string;
    companyKnowledgeBaseId: string;
    description: string;
}

export const CreateNewDocumentModal: React.FC<CreateNewDocumentModalProps> = ({
    tenderList,
    showModal,
    setShowModal,
    handleCreateDocument,
    isSaveStarted,
    selectedFolderID,
    companyKnowledgeBaseId,
    description
}) => {
    const { t } = useTranslation();
    const [isLoading, setLoading] = useState(false);
    const [documentName, setDocumentName] = useState("");
    const [aiInstructions, setAiInstructions] = useState(description);
    const [responseFromAI, setResponseFromAI] = useState("");
    const [isResponseDisabled, setIsResponseDisabled] = useState(true);
    let user = localStorage.getItem("tender_auth") ? parseJWT(localStorage.getItem("tender_auth")) : null;
    const fetchMentions = useFetchMentions(tenderList);

    const mentionInputStyle: MentionsInputStyle = {
        control: {
            width: '100%',
            minHeight: '75px',
            backgroundColor: '#fff',
            borderRadius: '4px',
            border: '1px solid #ccc',
            fontSize: '14px',
            boxSizing: 'border-box',
            color: '#1C3D5A',
            position: 'relative',
            overflow: 'auto'
        },
        input: {
            width: '100%',
            padding: '5px 15px',
            border: 'none',
            outline: 'none',
            backgroundColor: 'transparent',
            resize: 'none',
            color: '#1C3D5A',
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%'
        },
        suggestions: {
            list: {
                backgroundColor: 'white',
                border: '1px solid rgba(0,0,0,0.15)',
                fontSize: '14px',
                textAlign: 'left' as const,
                color: 'black',
            },
            item: {
                padding: '5px 15px',
                borderBottom: '1px solid rgba(0,0,0,0.15)',
                color: 'black',
                '&focused': {
                    backgroundColor: '#f0f0f0',
                },
            },
        },
        highlighter: {
            overflow: 'hidden',
            position: 'relative'
        },
    };

    const mentionStyle = {
        backgroundColor: '#e2e2e2',
        padding: '2px 4px',
        borderRadius: '4px',
    };

    if (!showModal) return null;

    const handleSave = async () => {
        setLoading(true)
        if (!documentName.trim()) {
            return;
        }

        try {
            if (isResponseDisabled) {
                const documentsArray: string[] = extractDocumentNames(aiInstructions);
                const newDocumentsByTab: any = {
                    tab_1: [],
                    tab_2: []
                };

                documentsArray.forEach(doc => {
                    const matchingFolder = tenderList.find(tender => tender.id === doc);
                    if (matchingFolder) {
                        matchingFolder.documents.forEach(doc => {
                            const documentObject = { document: doc, tenderId: matchingFolder.id };
                            if (matchingFolder.tab === 'tab_1') {
                                newDocumentsByTab.tab_1.push(documentObject);
                            } else if (matchingFolder.tab === 'tab_2') {
                                newDocumentsByTab.tab_2.push(documentObject);
                            }
                        });
                    } else {
                        const tender = tenderList.find(t => t.documents.includes(doc));
                        if (tender) {
                            const documentObject = { document: doc, tenderId: tender.id };
                            if (tender.tab === 'tab_1') {
                                newDocumentsByTab.tab_1.push(documentObject);
                            } else if (tender.tab === 'tab_2') {
                                newDocumentsByTab.tab_2.push(documentObject);
                            }
                        }
                    }
                });

                const selectedDocumentListParam = [
                    ...newDocumentsByTab.tab_1.length > 0 ? newDocumentsByTab.tab_1.map(item => item.document) : [],
                ];

                let tender_id = selectedFolderID;
                let selectedFolderIDParam = selectedFolderID;
                let companyKnowledgeBaseIdParam = companyKnowledgeBaseId;

                if (newDocumentsByTab.tab_1.length > 0) {
                    tender_id = newDocumentsByTab.tab_1[0].tenderId;
                    selectedFolderIDParam = newDocumentsByTab.tab_1[0].tenderId;
                }

                if (newDocumentsByTab.tab_2.length > 0) {
                    companyKnowledgeBaseIdParam = newDocumentsByTab.tab_2[0].tenderId;
                }

                const params = {
                    email: user.sub.email,
                    companyId: user.sub.companyId,
                    question: aiInstructions + "\n",
                    selectedDocumentList: selectedDocumentListParam,
                    selectedFolderID: selectedFolderIDParam,
                    tender_id: selectedFolderIDParam,
                    companyKnowledgeBaseId: companyKnowledgeBaseIdParam,
                    documentsForCompanyKnowledgebade: newDocumentsByTab.tab_2.length > 0 ? newDocumentsByTab.tab_2.map(item => item.document) : []
                };

                const response: any = await fetch(process.env.REACT_APP_API_URL + "api/getAnswer", {
                    method: 'POST',
                    body: JSON.stringify(params),
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                        'Content-Type': 'application/json'
                    }
                });

                const reader = response.body.getReader();
                const decoder = new TextDecoder();
                let done = false;
                let aiResponse = "";

                while (!done) {
                    const { value, done: doneReading } = await reader.read();
                    done = doneReading;
                    const chunkValue = decoder.decode(value);
                    var regex = /([^:]*)json_data:(\{.*?\})/g;
                    var match = regex.exec(chunkValue);
                    if (match && match.length >= 3) {
                        var beforeText = match[1];
                        if (beforeText) {
                            aiResponse += beforeText;
                        }
                        var jsonData = match[2];
                        // Handle jsonData if needed
                    } else {
                        aiResponse += chunkValue;
                    }
                    setResponseFromAI(aiResponse);
                }
                setIsResponseDisabled(false);
            }else {
                await handleCreateDocument(documentName, responseFromAI);
                setResponseFromAI("")
                setDocumentName("")
                setAiInstructions("")
                setShowModal(false)
            }

        } catch (error) {
            toast.error(t("Error creating document"));
        }
        setLoading(false)
    };

    return (
        <>
            <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
                <div className="relative w-[360px] my-6 mx-auto md:w-[75%]">
                    <div className="relative flex flex-col w-full border-0 rounded-lg shadow-lg outline-none bg-main_bg focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid rounded-t border-slate-200">
                            <h3 className="text-3xl font-semibold text-black">
                                {t('Create New Document')}
                            </h3>
                        </div>
                        <div className="relative flex-auto p-6">
                            <div className="mb-4">
                                <label className="block mb-2 text-sm font-medium text-gray-700">
                                    {t("Document Name")}
                                </label>
                                <input
                                    type="text"
                                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-cyan-500"
                                    placeholder={t("Write document name here.")}
                                    value={documentName}
                                    onChange={(e) => setDocumentName(e.target.value)}
                                    autoFocus
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block mb-2 text-sm font-medium text-gray-700">
                                    {t("AI Instructions")}
                                </label>
                                <MentionsInput
                                    value={aiInstructions}
                                    onChange={(event, newValue) => setAiInstructions(newValue)}
                                    style={mentionInputStyle}
                                    placeholder={t("Write AI instructions here")}
                                    className="focus:border-main_color_1 focus:outline-none"
                                    singleLine={false}
                                    allowSuggestionsAboveCursor={true}
                                    forceSuggestionsAboveCursor={true}
                                >
                                    <Mention
                                        trigger="@"
                                        data={fetchMentions}
                                        style={mentionStyle}
                                        appendSpaceOnAdd={true}
                                        renderSuggestion={(
                                            suggestion: SuggestionDataItem & { icon?: string },
                                            search,
                                            highlightedDisplay,
                                            index,
                                            focused
                                        ) => (
                                            <div
                                                className={`mention-suggestion ${focused ? 'focused' : ''}`}
                                                style={{ display: 'flex', alignItems: 'center', gap: '8px', padding: '5px 10px' }}
                                            >
                                                <span>{(suggestion as CustomSuggestionDataItem).icon}</span>
                                                <span className="text-gray-600">{suggestion.display}</span>
                                            </div>
                                        )}
                                    />
                                </MentionsInput>
                            </div>
                            <div className="mb-4">
                                <label className="block mb-2 text-sm font-medium text-gray-700">
                                    {t("Response from AI")}
                                </label>
                                <textarea
                                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-cyan-500"
                                    rows={5}
                                    value={responseFromAI}
                                    onChange={(e) => setResponseFromAI(e.target.value)}
                                    disabled={isResponseDisabled}
                                ></textarea>
                            </div>
                        </div>
                        <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-slate-200">
                            <button
                                className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                                onClick={() => {
                                    setShowModal(false)
                                    setDocumentName("")
                                    setAiInstructions(description)
                                    setResponseFromAI("")
                                    setIsResponseDisabled(true)
                                }}
                            >
                                {t("Close")}
                            </button>
                            {isSaveStarted ? (
                                <LoadingBar />
                            ) : (
                                <button
                                    className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-primary_button hover:bg-primary_button_hover hover:shadow-lg focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
                                    onClick={handleSave}
                                    disabled={isLoading}
                                >
                                    {isResponseDisabled ? t("Create") : t("Save")}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </>
    );
}; 