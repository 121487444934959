import { Prompt } from './PromptCard';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import axios from 'axios';
import { MentionsInput, Mention, MentionsInputStyle, SuggestionDataItem } from 'react-mentions';
import { useFetchMentions } from '../../utils/mentions';

interface CustomSuggestionDataItem extends SuggestionDataItem {
  icon?: string;
  type?: string;
  tender_id?: string;
  documents?: string[];
  tab?: string;
}

interface InsightModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: () => void;
    modalAction: 'Creation' | 'Edition';
    initialData: any;
    selectedFolderID: string;
    tenderList: any[];
    openedDocument: string;
    handleEditPrompt: (prompt: Prompt) => void;
    handleDocumentClick: any;
    isFromModal: any;
    setIsFromModal: any;
    setOpenedStatus: any;
    handleSelectedFolder: any;
    selectedTab: any;
    setTabName: any;
    setAutoFilling: any;
    socketId: string;
    companyKnowledgeBaseId: string;
}

export const InsightModal: React.FC<InsightModalProps> = ({
    isOpen,
    onClose,
    onSave,
    modalAction,
    initialData,
    selectedFolderID,
    tenderList,
    openedDocument,
    handleEditPrompt,
    handleDocumentClick,
    isFromModal,
    setIsFromModal,
    setOpenedStatus,
    handleSelectedFolder,
    selectedTab,
    setTabName,
    setAutoFilling,
    socketId,
    companyKnowledgeBaseId
}) => {
    const { t } = useTranslation();
    const [promptTitle, setPromptTitle] = useState(initialData?.title || '');
    const [promptText, setPromptText] = useState(initialData?.text || '');
    const [isGlobalInsight, setGlobalInsight] = useState<boolean>(
        initialData?.isGlobalInsight === "Yes" ? true : false
    );
    const [promptId] = useState(initialData?.id || '');
    const fetchMentions = useFetchMentions(tenderList);
    const mentionInputStyle: MentionsInputStyle = {
        control: {
            width: '100%',
            minHeight: '75px',
            backgroundColor: '#fff',
            borderRadius: '4px',
            border: '1px solid #ccc',
            fontSize: '14px',
            boxSizing: 'border-box',
            color: '#1C3D5A',
            position: 'relative',
            overflow: 'auto'
        },
        input: {
            width: '100%',
            padding: '5px 15px',
            border: 'none',
            outline: 'none',
            backgroundColor: 'transparent',
            resize: 'none',
            color: '#1C3D5A',
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%'
        },
        suggestions: {
            list: {
                backgroundColor: 'white',
                border: '1px solid rgba(0,0,0,0.15)',
                fontSize: '14px',
                textAlign: 'left' as const,
                color: 'black',
            },
            item: {
                padding: '5px 15px',
                borderBottom: '1px solid rgba(0,0,0,0.15)',
                color: 'black',
                '&focused': {
                    backgroundColor: '#f0f0f0',
                },
            },
        },
        highlighter: {
            overflow: 'hidden',
            position: 'relative'
        },
    };

    const mentionStyle = {
        backgroundColor: '#e2e2e2',
        padding: '2px 4px',
        borderRadius: '4px',
    };
    const handleSave = async () => {
        try {
            const user = localStorage.getItem("tender_auth") ? JSON.parse(atob(localStorage.getItem("tender_auth")?.split('.')[1] || '')) : null;

            const promptType = initialData?.type;
            if (!promptType) {
                throw new Error('Prompt type is required');
            }

            const formData = new FormData();
            if (modalAction === 'Edition' && initialData?.id) {
                formData.append('id', initialData.id);
            }
            formData.append('title', promptTitle);
            formData.append('text', promptText);
            formData.append('email', user.sub.email);
            formData.append('companyId', user.sub.companyId);
            formData.append('isGlobalInsight', isGlobalInsight ? "Yes" : "No");
            formData.append('selectedFolderID', selectedFolderID);
            formData.append('type', promptType);

            const endpoint = modalAction === 'Creation' 
                ? process.env.REACT_APP_API_URL + "api/createPrompt"
                : process.env.REACT_APP_API_URL + "api/editPrompt";

            const result = await axios.post(endpoint, formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                }
            });

            if (result.status === 200) {
                if (initialData) {
                    initialData.isGlobalInsight = isGlobalInsight;
                }
                toast.success(t(modalAction === 'Creation' ? "Successfully Created!" : "Successfully Edited!"), {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: 0,
                    toastId: "my_toast",
                });
                onSave();
                onClose();
            }
        } catch (error) {
            console.error('Error saving prompt:', error);
            toast.error(t("Error saving prompt"), {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    if (!isOpen) return null;

    return (
        <>
            <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
                <div className="relative w-[360px] my-6 mx-auto md:w-[50%]">
                    <div className="relative flex flex-col w-full bg-white rounded-xl shadow-lg outline-none">
                        {/* Header */}
                        <div className="flex items-center justify-between p-6 border-b">
                            <h3 className="text-xl font-semibold text-gray-900">
                                {t("Insight prompt")}
                            </h3>
                            <button
                                onClick={onClose}
                                className="text-gray-400 hover:text-gray-500"
                            >
                                <span className="sr-only">{t("Close")}</span>
                                <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>

                        {/* Body */}
                        <div className="relative flex-auto p-6 space-y-4">
                            <div>
                                <input
                                    type="text"
                                    className="w-full px-3 py-2 border border-gray-300 text-main_color_2 rounded-lg focus:border-main_color_1 focus:outline-none"
                                    placeholder={t("Prompt Title")}
                                    onChange={(e) => setPromptTitle(e.target.value)}
                                    value={promptTitle}
                                />
                            </div>

                            <div>
                                <MentionsInput
                                    value={promptText}
                                    onChange={(event, newValue, newPlainTextValue, mentions) => {
                                        if (newValue !== promptText) {
                                            setPromptText(newValue);
                                        }
                                    }}
                                    style={mentionInputStyle}
                                    placeholder={t("Write your prompts here...")}
                                    className="focus:border-main_color_1 focus:outline-none"
                                    singleLine={false}
                                    allowSuggestionsAboveCursor={true}
                                    forceSuggestionsAboveCursor={true}
                                >
                                    <Mention
                                        trigger="@"
                                        data={fetchMentions}
                                        style={mentionStyle}
                                        appendSpaceOnAdd={true}
                                        renderSuggestion={(
                                            suggestion: SuggestionDataItem & { icon?: string }, 
                                            search, 
                                            highlightedDisplay, 
                                            index, 
                                            focused
                                        ) => (
                                            <div 
                                                className={`mention-suggestion ${focused ? 'focused' : ''}`} 
                                                style={{ display: 'flex', alignItems: 'center', gap: '8px', padding: '5px 10px' }}
                                            >
                                                <span>{(suggestion as CustomSuggestionDataItem).icon}</span>
                                                <span className="text-gray-600">{suggestion.display}</span>
                                            </div>
                                        )}
                                    />
                                </MentionsInput>
                            </div>

                            <div className="flex flex-col space-y-3">
                                <div className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        className="w-4 h-4 appearance-none checked:bg-orange-500 bg-white border border-gray-300 rounded focus:outline-none checked:border-transparent"
                                        style={{
                                            backgroundImage: 'url("data:image/svg+xml,%3csvg viewBox=%270 0 16 16%27 fill=%27white%27 xmlns=%27http://www.w3.org/2000/svg%27%3e%3cpath d=%27M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z%27/%3e%3c/svg%3e")',
                                            backgroundSize: '100% 100%',
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat'
                                        }}
                                        checked={isGlobalInsight}
                                        onChange={() => setGlobalInsight(true)}
                                    />
                                    <span className="text-sm text-gray-700">{t("Template prompt (Global)")}</span>
                                </div>

                                <div className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        className="w-4 h-4 appearance-none checked:bg-orange-500 bg-white border border-gray-300 rounded focus:outline-none checked:border-transparent"
                                        style={{
                                            backgroundImage: 'url("data:image/svg+xml,%3csvg viewBox=%270 0 16 16%27 fill=%27white%27 xmlns=%27http://www.w3.org/2000/svg%27%3e%3cpath d=%27M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z%27/%3e%3c/svg%3e")',
                                            backgroundSize: '100% 100%',
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat'
                                        }}
                                        checked={!isGlobalInsight}
                                        onChange={() => setGlobalInsight(false)}
                                    />
                                    <span className="text-sm text-gray-700">{t("Use towards selected folders (Locally)")}</span>
                                </div>
                            </div>
                        </div>

                        {/* Footer */}
                        <div className="flex items-center justify-end p-6 space-x-3 border-t bg-gray-50 rounded-b-xl">
                            <button
                                className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-900"
                                onClick={onClose}
                            >
                                {t("Close")}
                            </button>

                            <button
                                className="px-4 py-2 text-sm font-medium text-white bg-orange-500 rounded-lg hover:bg-orange-600"
                                onClick={handleSave}
                            >
                                {t("Save Changes")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}; 