import React, { useEffect, useState, useRef } from 'react'
import "../../../App.css"
import { parseJWT } from '../../../utils'
import { ReactComponent as CheckedIcon } from "../../../assets/svg/checked.svg";
import { ReactComponent as UncheckedIcon } from "../../../assets/svg/unchecked.svg";
import { ReactComponent as InsightRefreshIcon } from "../../../assets/svg/insight_refresh.svg";
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import DocumentsTable from './DocumentsTable';
import { extractDocumentNames } from '../../../utils/mentions';
import { InsightModal } from '../../../Components/Shared/InsightModal';
import { PromptCard, Prompt } from '../../../Components/Shared/PromptCard';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import { SummaryCard } from '../../Shared/SummaryCard';
const { default: axios } = require('axios');

interface Summary {
    title: string;
    document: string;
    summary: string;
}

interface InsightsProps {
    openedDocument: string;
    promptList: Prompt[];
    selectedDocumentList: any[];
    handleEditPrompt: (prompt: Prompt) => void;
    selectedFolderID: string;
    promptDataFetch: () => Promise<void>;
    summaries: Summary[];
    handleDocumentClick: (document: string) => void;
    isFromModal: boolean;
    setIsFromModal: (value: boolean) => void;
    tenderList: any[];
    setTenderList: (list: any[]) => void;
    setOpenedStatus: (status: string) => void;
    handleSelectedFolder: (folder: string) => void;
    selectedTab: string;
    setTabName: (name: string) => void;
    setSummaryList: (list: Summary[]) => void;
    setCurrentSlide: (slide: number) => void;
    setAutoFilling: (value: boolean) => void;
    socketId: string;
    companyKnowledgeBaseId: string;
    dbSaveSuccess: () => void;
}

const createMarkup = (text: string) => {
    const rawMarkup = marked(text);
    const cleanMarkup = DOMPurify.sanitize(rawMarkup);
    return { __html: cleanMarkup };
};

export const Insights: React.FC<InsightsProps> = ({ setSummaryList, openedDocument, promptList, selectedDocumentList, handleEditPrompt, selectedFolderID, promptDataFetch, summaries, handleDocumentClick, isFromModal, setIsFromModal, tenderList, setTenderList, setOpenedStatus, handleSelectedFolder, selectedTab, setTabName, setCurrentSlide, setAutoFilling, socketId, companyKnowledgeBaseId, dbSaveSuccess }) => {
    const [insightPromptList, setPromptList] = useState<Prompt[]>([]);
    const [insightStates, setInsightStates] = useState<Map<string, boolean>>(() => {
        const map = new Map<string, boolean>();
        try {
            const storedStates = localStorage.getItem('insightStates');
            if (storedStates) {
                const parsed = JSON.parse(storedStates);
                Object.entries(parsed).forEach(([key, value]) => {
                    map.set(key, value as boolean);
                });
            }
        } catch (e) {
            console.error('Error loading insight states:', e);
        }
        return map;
    });
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [activeDropdownIndex, setActiveDropdownIndex] = useState(null);
    const [isRefreshing, setRefreshing] = useState(false);
    const [runSync, setRunSync] = useState(false);
    const [documents, setDocuments] = useState<any>(null);
    const { t } = useTranslation();
    let user = localStorage.getItem("tender_auth") ? parseJWT(localStorage.getItem("tender_auth")) : null;
    const [showModal, setShowModal] = useState(false);
    const [modalAction, setModalAction] = useState<'Creation' | 'Edition'>('Creation');
    const [initialData, setInitialData] = useState<any>(null);
    const [activeCompareDropdownIndex, setActiveCompareDropdownIndex] = useState<number | null>(null);

    useEffect(() => {
        if (promptList) {
            setPromptList(promptList);
        }
    }, [promptList]);

    useEffect(() => {
        if (isFromModal == true) {
            setTimeout(() => {
                syncAnswers()
            }, 1000)
        }
    }, [isFromModal]);
    useEffect(() => {
    }, [openedDocument])
    // setRunSync
    const refs = useRef<(HTMLDivElement | null)[]>(Array(insightPromptList.length).fill(null));

    useEffect(() => {
        if (selectedFolderID && selectedFolderID != "all_folders") {
            getDocumentsForSubmission("normal")
        }
    }, [selectedFolderID])
    /**
     * A function that handles getting prompt answers asynchronously.
     *
     * @param {string} text - The text input for the prompt.
     * @param {number} index - The index of the prompt.
     * @param {string} id - The ID of the prompt.
     * @param {string} isGlobalInsight - Flag indicating if the prompt is a global insight.
     * @return {Promise<void>} A Promise that resolves once the prompt answer is retrieved.
     */
    const getPromptAnswer = async (text: string, index: number, id: string, isGlobalInsight: string) => {
        const documentsArray: string[] = extractDocumentNames(text);
        console.log("documentsArray", documentsArray)
        const newDocumentsByTab: any = {
            tab_1: [],
            tab_2: []
        };

        documentsArray.forEach(doc => {
            const matchingFolder = tenderList.find(tender => tender.id === doc);
            if (matchingFolder) {
                // If it's a folder, add all its documents
                matchingFolder.documents.forEach(doc => {
                    const documentObject = { document: doc, tenderId: matchingFolder.id };
                    if (matchingFolder.tab === 'tab_1') {
                        newDocumentsByTab.tab_1.push(documentObject);
                    } else if (matchingFolder.tab === 'tab_2') {
                        newDocumentsByTab.tab_2.push(documentObject);
                    }
                });
            } else {
                const tender = tenderList.find(t => t.documents.includes(doc));
                if (tender) {
                    const documentObject = { document: doc, tenderId: tender.id };
                    if (tender.tab === 'tab_1') {
                        newDocumentsByTab.tab_1.push(documentObject);
                    } else if (tender.tab === 'tab_2') {
                        newDocumentsByTab.tab_2.push(documentObject);
                    }
                }
            }

        });

        const selectedDocumentListParam = [
            ...newDocumentsByTab.tab_1.length > 0 ? newDocumentsByTab.tab_1.map(item => item.document) : selectedDocumentList.map(item => item.document),
        ];

        // Set parameters
        let tender_id = selectedFolderID;
        let selectedFolderIDParam = selectedFolderID;
        let companyKnowledgeBaseIdParam = companyKnowledgeBaseId;

        if (newDocumentsByTab.tab_1.length > 0) {
            tender_id = newDocumentsByTab.tab_1[0].tenderId;
            selectedFolderIDParam = newDocumentsByTab.tab_1[0].tenderId;
        }

        if (newDocumentsByTab.tab_2.length > 0) {
            companyKnowledgeBaseIdParam = newDocumentsByTab.tab_2[0].tenderId;
        }

        setPromptList(prevList => {
            const newList = [...prevList];
            newList[index].isLoading = true;
            return newList;
        });
        isGlobalInsight = isGlobalInsight ? isGlobalInsight : "No"
        let params = {
            email: user.sub.email,
            companyId: user.sub.companyId,
            question: text + "\n",
            selectedDocumentList: selectedDocumentListParam,
            promptId: id,
            selectedFolderID: selectedFolderIDParam,
            tender_id: selectedFolderIDParam,
            companyKnowledgeBaseId: companyKnowledgeBaseIdParam,
            documentsForCompanyKnowledgebade: newDocumentsByTab.tab_2.length > 0 ? newDocumentsByTab.tab_2.map(item => item.document) : selectedDocumentList.filter(item => item?.tabName == "tab_2").map(item => item.document),
            isGlobalInsight: isGlobalInsight
        }
        const response: any = await fetch(process.env.REACT_APP_API_URL + "api/getAnswer", {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                'Content-Type': 'application/json'
            }
        });

        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let done = false;
        let isStarted = false;
        setPromptList(prevList => {
            const newList = [...prevList];
            newList[index].isLoading = false;
            return newList;
        });
        while (!done) {
            const { value, done: doneReading } = await reader.read();
            const updatedList: any[] = [...promptList];
            if (!isStarted) {
                if (!updatedList[index].response || typeof updatedList[index].response === 'string') {
                    updatedList[index].response = [];
                }
                const existingIndex = updatedList[index].response.findIndex((item: any) => Object.keys(item)[0] === selectedFolderID);
                if (existingIndex !== -1) {
                    updatedList[index].response[existingIndex][selectedFolderID] = '';
                } else {
                    updatedList[index].response.push({ [selectedFolderID]: '' });
                }
            }
            done = doneReading;
            const chunkValue = decoder.decode(value);
            var regex = /([^:]*)json_data:(\{.*?\})/g;
            var match = regex.exec(chunkValue);
            if (match && match.length >= 3) {
                var beforeText = match[1];
                if (beforeText) {
                    const existingIndex = updatedList[index].response.findIndex((item: any) => Object.keys(item)[0] === selectedFolderID);
                    if (existingIndex !== -1) {
                        updatedList[index].response[existingIndex][selectedFolderID] += beforeText
                    }
                    setPromptList(updatedList)
                }
                var jsonData = match[2];
            } else {
                const existingIndex = updatedList[index].response.findIndex((item: any) => Object.keys(item)[0] === selectedFolderID);
                if (existingIndex !== -1) {
                    updatedList[index].response[existingIndex][selectedFolderID] += chunkValue
                }
                if (!chunkValue.includes("</")) {
                    setPromptList(updatedList)
                }
            }
            isStarted = true
        }
    }

    const handlePlusButtonClick = () => {
        setShowModal(true);
        setModalAction('Creation');
        setInitialData({
            type: 'insight',
            isGlobalInsight: "No",
            tender_id: selectedFolderID,
            title: '',
            text: '',
            id: ''
        });
    }
    const handleModalClose = () => {
        setShowModal(false);
        setInitialData(null);
    }
    const handleModalSave = () => {
        promptDataFetch();
        setInitialData(null);
    }
    const [isLoading, setLoading] = useState(false);
    const syncAnswers = async () => {
        try {
            setRefreshing(true)
            await getInsights()
            await getDocumentsForSubmission("refresh");
            await createDocumentsSummary();
            setRefreshing(false)
            setIsFromModal(false);
        } catch (error) {
            setRefreshing(false)
            setIsFromModal(false);
        }
    }
    const getDocumentsForSubmission = async (type: any) => {
        setLoading(true)
        const formData: any = new FormData();
        formData.append('companyId', user.sub.companyId)
        formData.append('email', user.sub.email)
        formData.append('tender_id', selectedFolderID)
        formData.append('type', type)
        if (isFromModal == true) {
            formData.append('socketId', socketId)
        }
        const result = await axios.post(process.env.REACT_APP_API_URL + "api/getDocumentsForSubmission", formData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                "Content-Type": "application/json",
            }
        });
        if (result.status === 200 && result.data.documentsForSubmission) {
            setDocuments(result.data.documentsForSubmission)
            setLoading(false)
        }
    }
    const createDocumentsSummary = async () => {
        const formData: any = new FormData();
        formData.append('email', user.sub.email)
        formData.append("companyId", user.sub.companyId)
        formData.append('tender_id', selectedFolderID)
        if (isFromModal == true) {
            formData.append('socketId', socketId)
        }
        const result = await axios.post(process.env.REACT_APP_API_URL + "api/createDocumentsSummary", formData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                "Content-Type": "application/json",
            }
        });
        if (result.status == 200) {
            setSummaryList(result.data.data)
        }
    }
    const getInsights = async () => {
        for (let index = 0; index < insightPromptList.length; index++) {
            const prompt = insightPromptList[index];
            if (prompt.type === 'insight') {
                await getPromptAnswer(prompt.text, index, prompt.id, prompt?.isGlobalInsight);
            }
        }
    }

    const getInsightState = (folderId: string) => {
        return insightStates.get(folderId) ?? true; // Default til åpen
    };

    const saveInsightStates = (states: Map<string, boolean>) => {
        const obj = Object.fromEntries(states);
        localStorage.setItem('insightStates', JSON.stringify(obj));
    };

    const toggleCompareDropdown = (index: number, e: React.MouseEvent) => {
        e.stopPropagation();
        setActiveCompareDropdownIndex(activeCompareDropdownIndex === index ? null : index);
    };

    // Add this function to handle edit clicks
    const handleEditClick = (prompt: any) => {
        // Close both dropdowns
        setActiveCompareDropdownIndex(null);

        // Set modal data for editing
        setShowModal(true);
        setModalAction('Edition');
        setInitialData({
            id: prompt.id,
            title: prompt.title,
            text: prompt.text,
            type: prompt.type,
            isGlobalInsight: prompt.isGlobalInsight,
            tender_id: prompt.tender_id
        });
    };

    return (
        <>
            <div className='safari-scroll scrollbar-hide h-[300px] md:h-[calc(100vh-115px)]' onClick={() => activeDropdownIndex ? setActiveDropdownIndex(null) : null}>
                <div className="flex items-center mt-2">
                    <div className='flex items-center ml-2 text-xl font-medium text-left text-black'>
                        <img src={process.env.PUBLIC_URL + '/img/Insight_page2.png'} width={50} height={50} className='mr-[24px]'>
                        </img>
                        <div>
                            <div className='flex'>
                                <div className="text-xl font-bold text-left text-black font-['Montserrat']">{t('Agent Insight')}</div>
                                <div className='flex ml-[30px]'>
                                    {user?.sub?.membership == "trial" || user?.sub?.membership == "basic" || user?.sub?.membership == "basic extended" ?
                                        <div className='flex items-center'>
                                            <button className="h-7 ml-[10px] cursor-pointer relative group" onClick={getInsights}>
                                                <InsightRefreshIcon className='text-white' />
                                            </button>
                                        </div>
                                        :
                                        <div className='h-7'>
                                            <button className="ml-[10px] cursor-pointer" onClick={() => syncAnswers()}> {
                                                isRefreshing ? <svg aria-hidden="true" className="mr-2 text-gray-200 w-7 h-7 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                </svg> : <InsightRefreshIcon className='w-7 h-7' />
                                            }
                                            </button>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className='text-[15px]'>
                                {t("Agent_Insight_Description")}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="p-6 pb-[0] text-black bg-gray-100">
                        <div className="flex items-center cursor-pointer" onClick={() => {
                            const currentState = getInsightState(selectedFolderID);
                            const newStates = new Map(insightStates);
                            newStates.set(selectedFolderID, !currentState);
                            setInsightStates(newStates);
                            saveInsightStates(newStates);
                        }}>
                            <svg
                                className={`w-5 h-5 mr-2 transition-transform ${getInsightState(selectedFolderID) ? 'transform rotate-90' : ''}`}
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M9 5l7 7-7 7"
                                />
                            </svg>
                            <div className="flex items-center w-full">
                                <h1 className="text-xl font-bold text-left">{t('Insights')}</h1>
                                {user?.sub?.membership !== "trial" && user?.sub?.membership !== "basic" && user?.sub?.membership !== "basic extended" && (
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handlePlusButtonClick();
                                        }}
                                        className="ml-2 p-2 hover:bg-gray-200 rounded-full transition-colors text-black"
                                    >
                                        <svg
                                            className="w-6 h-6"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M12 4v16m8-8H4"
                                            />
                                        </svg>
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                    {getInsightState(selectedFolderID) && (
                        <div className='flex flex-wrap gap-6 p-6 mt-[0px] pl-[24px]'>
                            {insightPromptList
                                .map((prompt, index) => {
                                    if (prompt.type !== 'insight') {
                                        return null;
                                    }

                                    const targetInformationElement = document.getElementById("target_information");
                                    if (targetInformationElement) {
                                        const textContent = targetInformationElement.textContent;
                                        if (selectedTab === 'tab_2' && (textContent === "Company Documents" || textContent === "Selskapets dokumenter")) {
                                            return null;
                                        }
                                    }

                                    if (selectedFolderID === "all_folders") {
                                        return (
                                            <PromptCard
                                                key={index}
                                                prompt={prompt}
                                                index={index}  // This is now the original index
                                                selectedFolderID={selectedFolderID}
                                                companyKnowledgeBaseId={companyKnowledgeBaseId}
                                                selectedDocumentList={selectedDocumentList}
                                                membership={user?.sub?.membership}
                                                onUpdate={promptDataFetch}
                                                onEdit={handleEditClick}
                                                setPromptList={setPromptList}
                                                promptList={insightPromptList}
                                                tenderList={tenderList}
                                            />
                                        );
                                    }

                                    if (selectedFolderID === prompt.tender_id ||
                                        (prompt.isGlobalInsight === "Yes" && !prompt.deleted_folder_ids?.includes(selectedFolderID))) {
                                        return (
                                            <PromptCard
                                                key={index}
                                                prompt={prompt}
                                                index={index}  // This is now the original index
                                                selectedFolderID={selectedFolderID}
                                                companyKnowledgeBaseId={companyKnowledgeBaseId}
                                                selectedDocumentList={selectedDocumentList}
                                                membership={user?.sub?.membership}
                                                onUpdate={promptDataFetch}
                                                onEdit={handleEditClick}
                                                setPromptList={setPromptList}
                                                promptList={insightPromptList}
                                                tenderList={tenderList}
                                            />
                                        );
                                    }

                                    return null;
                                }).filter(Boolean)}
                        </div>
                    )}

                    <DocumentsTable setRunSync={setRunSync} setAutoFilling={setAutoFilling} setcurrentSlide={setCurrentSlide} setTabName={setTabName} selectedTab={selectedTab} getDocumentsForSubmission={getDocumentsForSubmission} documentsForSubmission={documents} isLoading={isLoading} selectedFolderID={selectedFolderID} handleDocumentClick={handleDocumentClick} tenderList={tenderList} setTenderList={setTenderList} setOpenedStatus={setOpenedStatus} handleSelectedFolder={handleSelectedFolder} dbSaveSuccess={dbSaveSuccess} companyKnowledgeBaseId={companyKnowledgeBaseId}></DocumentsTable>

                    {
                        summaries && summaries.length > 0 ? <><div className="p-6 pb-[0] text-black bg-gray-100">
                            <h1 className="text-xl font-bold text-left">{t('Summaries')}</h1>
                        </div>
                            <div className='flex flex-wrap m-[20px] ml-[0px]'>
                                {summaries.map((summary, index) => (
                                    <SummaryCard
                                        key={index}
                                        summary={summary}
                                        handleDocumentClick={handleDocumentClick}
                                        createMarkup={createMarkup}
                                    />
                                ))}
                            </div></> : <></>
                    }

                </div>
            </div>
            {showModal && (
                <InsightModal
                    isOpen={showModal}
                    onClose={handleModalClose}
                    onSave={handleModalSave}
                    modalAction={modalAction}
                    initialData={initialData}
                    selectedFolderID={selectedFolderID}
                    tenderList={tenderList}
                    openedDocument={openedDocument}
                    handleEditPrompt={handleEditPrompt}
                    handleDocumentClick={handleDocumentClick}
                    isFromModal={isFromModal}
                    setIsFromModal={setIsFromModal}
                    setOpenedStatus={setOpenedStatus}
                    handleSelectedFolder={handleSelectedFolder}
                    selectedTab={selectedTab}
                    setTabName={setTabName}
                    setAutoFilling={setAutoFilling}
                    socketId={socketId}
                    companyKnowledgeBaseId={companyKnowledgeBaseId}
                />
            )}
        </>
    );

}